/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Edsn.WebApi.PiE
 * OpenAPI spec version: 1.0
 */

export type Period = (typeof Period)[keyof typeof Period];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Period = {
  Other: 'Other',
  Past5Years: 'Past5Years',
  _2019: '_2019',
  _2020: '_2020',
  _2021: '_2021',
  _2022: '_2022',
  _2023: '_2023',
} as const;
