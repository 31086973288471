/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Edsn.WebApi.PiE
 * OpenAPI spec version: 1.0
 */

export type DataRelatedToDataset = (typeof DataRelatedToDataset)[keyof typeof DataRelatedToDataset];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DataRelatedToDataset = {
  Yes: 'Yes',
  No: 'No',
  NoEnergyNeighbourScan: 'NoEnergyNeighbourScan',
} as const;
