/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Edsn.WebApi.PiE
 * OpenAPI spec version: 1.0
 */

export type DataRequestReceiver = (typeof DataRequestReceiver)[keyof typeof DataRequestReceiver];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DataRequestReceiver = {
  AllGridOperators: 'AllGridOperators',
  Coteq: 'Coteq',
  Enexis: 'Enexis',
  Liander: 'Liander',
  Rendo: 'Rendo',
  Stedin: 'Stedin',
  WestlandInfra: 'WestlandInfra',
} as const;
