import { useAtomValue } from 'jotai';
import { ConnectionInformationDetail } from './ConnectionInformationDetail';
import { ConnectionInformationForm } from './ConnectionInformationForm';
import { Page } from '#pie/components/page/Page';
import { PageHead } from '#pie/components/page-head/PageHead';
import { searchAtom } from '#pie/stores/search';

export const ConnectionInformation = () => {
  const search = useAtomValue(searchAtom);

  return (
    <>
      <PageHead title="Aansluitingsinformatie" />
      <Page>{search ? <ConnectionInformationDetail /> : <ConnectionInformationForm />}</Page>
    </>
  );
};
