import addIcon from '@iconify/icons-material-symbols/add';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { CreateSubcategoryNotificationDto } from '#edsn/api/pie-bff';
import { getGetNotificationQueryKey, useGetNotification, usePostNotificationSubCategory } from '#edsn/api/pie-bff';
import { TicketCategoriesDropdowns } from '../ticket-category-dropdowns/TicketCategoryDropdowns';
import type { SubmitHandler } from 'react-hook-form';
import { Button } from '#pie/components/button/Button';
import { Dialog } from '#pie/components/layout/dialog/Dialog';
import { Stack } from '#pie/components/stack/Stack';
import { useToast } from '#pie/components/toast/ToastContext';
import { typedFormFields } from '#pie/utils/typedFormFields';

interface Form extends Omit<CreateSubcategoryNotificationDto, 'email'> {
  categoryEmail: string;
}

const { FormText } = typedFormFields<Form>();

export const AddNotificationButton = () => {
  const { addToast } = useToast();
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const { data, isLoading } = useGetNotification();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const formMethods = useForm<Form>();

  const { mutate, isLoading: isMutating } = usePostNotificationSubCategory({
    mutation: {
      onError: () => {
        addToast({
          message: `${t('organisation_management.tab.notifications.add.toast.error.message')}`,
          title: `${t('organisation_management.tab.notifications.add.toast.error.title')}`,
          type: 'error',
        });
      },
      onSuccess: () => {
        gtag('event', 'add_notifation_category', {});
        addToast({
          message: `${t('organisation_management.tab.notifications.add.toast.success.message')}`,
          title: `${t('organisation_management.tab.notifications.add.toast.success.title')}`,
          type: 'success',
        });
        setOpenAddDialog(false);
        queryClient.removeQueries(getGetNotificationQueryKey());
        queryClient.removeQueries({
          queryKey: ['/notification'],
        });
      },
    },
  });

  const onSubmit: SubmitHandler<Form> = data => {
    mutate({
      data: {
        email: data.categoryEmail,
        subcategoryId: data.subcategoryId,
      },
    });
  };

  return (
    <>
      <Button variant="ghost" iconStart={addIcon} className="self-start" onClick={() => setOpenAddDialog(true)}>
        {t('organisation_management.tab.notifications.add.button')}
      </Button>
      <Dialog
        title={t('organisation_management.tab.notifications.add.title')}
        open={openAddDialog}
        onOpenChange={setOpenAddDialog}
      >
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <Stack gap="lg">
              <TicketCategoriesDropdowns
                excludedSubcategories={data?.subcategories.map(cat => cat.subcategoryId)}
                isLoading={isLoading}
              />
              <FormText
                name="categoryEmail"
                label={t('common.email')}
                placeholder={t('organisation_management.tab.notifications.add.email.placeholders')}
                fullWidth
                rules={{ required: true }}
              />
              <Button isLoading={isMutating || isLoading} variant="secondary" type="submit">
                {t('organisation_management.tab.notifications.add.submit')}
              </Button>
            </Stack>
          </form>
        </FormProvider>
      </Dialog>
    </>
  );
};
