import AddIcon from '@iconify/icons-material-symbols/add';
import IconInfo from '@iconify/icons-material-symbols/info-outline-rounded';
import list from '@iconify/icons-material-symbols/list';
import WarningIcon from '@iconify/icons-material-symbols/warning';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useGetConnectionInformation } from '#edsn/api/pie-bff';
import { Button } from '#pie/components/button/Button';
import { Card } from '#pie/components/card/Card';
import { ConnectionInfo } from '#pie/components/connection-info/ConnectionInfo';
import { FailureInfo } from '#pie/components/failure-info/FailureInfo';
import { Icon } from '#pie/components/icon/Icon';
import { Message } from '#pie/components/message/Message';
import { Page, PageHeader } from '#pie/components/page/Page';
import { PageHead } from '#pie/components/page-head/PageHead';
import { Stack } from '#pie/components/stack/Stack';
import { Text } from '#pie/components/text/Text';
import { TicketTableEan } from '#pie/components/ticket-table-ean/TicketTableEan';
import { searchAtom } from '#pie/stores/search';

export const ConnectionInformationDetail = () => {
  const { t } = useTranslation();
  const [eanParam, setEan] = useAtom(searchAtom);
  const ean = eanParam!;

  const { data, isLoading } = useGetConnectionInformation({ ean18: ean });

  return (
    <>
      <PageHead
        title={t('connection_information_detail.header.title', {
          connectionEan: ean,
        })}
      />
      <Page>
        <PageHeader
          title={t('connection_information_detail.header.title', {
            connectionEan: ean,
          })}
          className="mb-8"
          backOnClick={() => setEan(undefined)}
          previousText={t('connection_information_detail.button.back')}
        >
          <Button as={Link} iconStart={AddIcon} to="/tickets/nieuw" variant="ghost">
            {t('connection_information_detail.header.button.new')}
          </Button>
        </PageHeader>
      </Page>

      <Stack gap="lg" className="mx-auto my-6 max-w-4xl">
        {!isLoading && (
          <>
            {!data ? (
              <Message variant="error" icon={WarningIcon}>
                {t('connection_information_component_error.message')}
              </Message>
            ) : !data?.deviceStatus ? (
              <>
                <Message icon={IconInfo} variant="error">
                  {t('connection_information_detail.message.unauthorized')}
                </Message>
                <ConnectionInfo ean18={ean} isCollapsible={false} />
              </>
            ) : (
              <>
                <FailureInfo ean18={ean} isCollapsible={false} showEan={false} showNoFailure />
                <ConnectionInfo ean18={ean} isCollapsible={false} />
              </>
            )}
          </>
        )}

        <Stack gap="sm" as="section">
          <Card
            heading={
              <div className="flex items-center gap-2">
                <Icon className="text-primary-dark" icon={list} />
                <Text as="h6" variant="h6" className="text-primary-dark">
                  {t('connection_information_detail.text.tickethistory')}
                </Text>
              </div>
            }
            size="md"
            shadow={false}
          >
            <Stack gap="md" divider="line">
              <TicketTableEan ean={ean} />
            </Stack>
          </Card>
        </Stack>
      </Stack>
    </>
  );
};
