import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import type { PropsWithChildren } from 'react';
import type { Path, PathValue } from 'react-hook-form';

interface Props<T extends object> extends PropsWithChildren {
  field: Path<T>;
  condition: (formValue: <TPath extends Path<T>>(field: TPath) => PathValue<T, TPath>) => boolean;
}

export const ConditionalField = <T extends object>({ condition, field, children }: Props<T>) => {
  const { watch, resetField } = useFormContext<T>();
  const shouldUse = condition(f => watch(f));

  useEffect(() => {
    if (!shouldUse) resetField(field);
  }, [shouldUse]);

  return shouldUse && children;
};
