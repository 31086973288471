import infoIcon from '@iconify/icons-material-symbols/info-outline';
import { Icon } from '../icon/Icon';
import { Tooltip } from '../tooltip/Tooltip';
import type { ComponentProps, ReactNode } from 'react';

interface Props extends Omit<ComponentProps<typeof Tooltip>, 'children' | 'message'> {
  children: ReactNode;
}

export const InfoTooltip = ({ children }: Props) => (
  <Tooltip message={children}>
    <Icon icon={infoIcon} size={16} />
  </Tooltip>
);
