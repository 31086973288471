import { useTranslation } from 'react-i18next';
import type { CreateDataRequestDto } from '#edsn/api/pie-bff';
import { AddressLevel, DataLevel } from '#edsn/api/pie-bff';
import type { Option } from '#pie/components/input-radio/InputRadio';
import { CraftAssetDownload } from '#pie/components/craft-asset-download/CraftAssetDownload';
import { typedFormFields } from '#pie/utils/typedFormFields';

const { ConditionalField, FormRadio, FormFile } = typedFormFields<CreateDataRequestDto>();

export const LevelAddress = () => {
  const { t } = useTranslation('dataRequest');
  const options: Option[] = [
    { label: t(`address_level.${AddressLevel.Individual}`), value: AddressLevel.Individual },
    { label: t(`address_level.${AddressLevel.Cluster}`), value: AddressLevel.Cluster },
  ];
  return (
    <>
      <ConditionalField field="levelFile" condition={fieldValue => fieldValue('level') === DataLevel.Address}>
        <FormFile name="levelFile" label={t('address_level_file')} rules={{ required: true }} />
        <CraftAssetDownload title="Aanleveren clusters voorbeeldbestand" />
      </ConditionalField>
      <ConditionalField field="addressLevel" condition={fieldValue => fieldValue('level') === DataLevel.Address}>
        <FormRadio label={t('address_level')} name="addressLevel" rules={{ required: true }} options={options} />
      </ConditionalField>
    </>
  );
};
