import AddIcon from '@iconify/icons-material-symbols/add';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getGetTeamIdQueryKey, usePostContactDetailsIdTeam } from '#edsn/api/pie-bff';
import { Button } from '../button/Button';
import { Stack } from '../stack/Stack';
import { useToast } from '../toast/ToastContext';
import { FormUserCombobox } from '../user-combobox/FormUserCombobox';
import type { SubmitHandler } from 'react-hook-form';
import { Dialog } from '#pie/components/layout/dialog/Dialog';

interface FormAddTeamMember {
  userId: string;
}

interface Props {
  teamId: string;
}

export const AddTeamMemberModal = ({ teamId }: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = useState(false);

  const { addToast } = useToast();

  const formMethods = useForm<FormAddTeamMember>();

  const { handleSubmit } = formMethods;

  const { mutate, isLoading } = usePostContactDetailsIdTeam({
    mutation: {
      onError: () => {
        addToast({
          message: t('cpr_contact_group_detail.add_user.toast.error.message'),
          title: t('cpr_contact_group_detail.add_user.toast.error.title'),
          type: 'error',
        });
      },
      onSuccess: (_data, variables) => {
        gtag('event', 'link-team-member', {});

        queryClient.invalidateQueries(getGetTeamIdQueryKey(teamId));
        queryClient.removeQueries({
          queryKey: ['/contact/details'],
        });
        /* c8 ignore start */
        queryClient.invalidateQueries({
          predicate: m => {
            return m.queryKey.some(n => typeof n === 'string' && n.includes(variables.id));
          },
        });
        /* c8 ignore end */
        formMethods.reset();
        addToast({
          message: t('organisation_management.tab.add_team_member.toast.succes.title'),
          title: t('cpr_contact_group_detail.add_user.toast.success.title'),
          type: 'success',
        });
        setIsOpen(false);
      },
    },
  });

  const onSubmit: SubmitHandler<FormAddTeamMember> = data => {
    mutate({ data: { teamId }, id: data.userId });
  };

  return (
    <>
      <Button iconStart={AddIcon} variant="secondary" onClick={() => setIsOpen(true)}>
        {t('organisation_management.tab.add_team_member.button.create')}
      </Button>

      {isOpen && (
        <Dialog
          title={t('organisation_management.tab.add_team_member.title')}
          size="md"
          open={isOpen}
          onOpenChange={setIsOpen}
        >
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack gap="lg">
                <FormUserCombobox
                  label={t('organisation_management.tab.add_team_member.create.dialog.title')}
                  name="userId"
                  rules={{ required: true }}
                  fullWidth
                  filter={{ excludeTeamIds: [teamId] }}
                />
                <Button isLoading={isLoading} type="submit" variant="secondary">
                  {t('organisation_management.tab.add_team_member.button.add')}
                </Button>
              </Stack>
            </form>
          </FormProvider>
        </Dialog>
      )}
    </>
  );
};
