import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { CreateDataRequestDto } from '#edsn/api/pie-bff';
import { DataRequestReceiver, ReasonForDataRequest } from '#edsn/api/pie-bff';
import type { FormCheckboxType } from '#pie/components/form/types';
import { Button } from '#pie/components/button/Button';
import { typedFormFields } from '#pie/utils/typedFormFields';

export const DataRequestTo = () => {
  const { t } = useTranslation('dataRequest');

  const { FormCheckbox, ConditionalField } = typedFormFields<CreateDataRequestDto>();
  const options: FormCheckboxType[] = [
    {
      label: t(`data_request_receiver.${DataRequestReceiver.AllGridOperators}`),
      value: DataRequestReceiver.AllGridOperators,
    },
    { label: t(`data_request_receiver.${DataRequestReceiver.Coteq}`), value: DataRequestReceiver.Coteq },
    { label: t(`data_request_receiver.${DataRequestReceiver.Enexis}`), value: DataRequestReceiver.Enexis },
    { label: t(`data_request_receiver.${DataRequestReceiver.Liander}`), value: DataRequestReceiver.Liander },
    { label: t(`data_request_receiver.${DataRequestReceiver.Rendo}`), value: DataRequestReceiver.Rendo },
    { label: t(`data_request_receiver.${DataRequestReceiver.Stedin}`), value: DataRequestReceiver.Stedin },
    {
      label: t(`data_request_receiver.${DataRequestReceiver.WestlandInfra}`),
      value: DataRequestReceiver.WestlandInfra,
    },
  ];

  const { watch, setValue } = useFormContext<CreateDataRequestDto>();
  const gridOperatorData = watch('dataRequestTo');

  useEffect(() => {
    if (gridOperatorData?.slice(-1)[0] === DataRequestReceiver.AllGridOperators) {
      setValue('dataRequestTo', [DataRequestReceiver.AllGridOperators]);
    } else if (gridOperatorData?.includes(DataRequestReceiver.AllGridOperators)) {
      setValue('dataRequestTo', gridOperatorData?.filter(value => value !== DataRequestReceiver.AllGridOperators));
    }
  }, [gridOperatorData, setValue]);

  return (
    <ConditionalField
      field="dataRequestTo"
      condition={fieldValue => {
        return (
          (!!fieldValue('reason') && fieldValue('reason') !== ReasonForDataRequest.Other) ||
          (!!fieldValue('reasonNote') && fieldValue('reasonNote')!.length > 4) ||
          (!!fieldValue('dataRelatedToDatasetNote') && fieldValue('dataRelatedToDatasetNote')!.length > 1)
        );
      }}
    >
      <FormCheckbox
        label={t('data_request_to')}
        options={options}
        name="dataRequestTo"
        infoTooltip={
          <>
            {t('data_request_to.tooltip.part_one')}
            <Button
              variant="linkPrimary"
              as="a"
              href="https://www.netbeheernederland.nl/onze-leden"
              target="_blank noreferror noopener"
            >
              {t('data_request_to.tooltip.part_link')}
            </Button>
            {t('data_request_to.tooltip.part_two')}
          </>
        }
        rules={{ required: true }}
      />
    </ConditionalField>
  );
};
