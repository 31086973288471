import { useTranslation } from 'react-i18next';
import { type CreateDataRequestDto, DataType, EnergyType } from '#edsn/api/pie-bff';
import type { FormCheckboxType } from '#pie/components/form/types';
import { typedFormFields } from '#pie/utils/typedFormFields';

const { FormCheckbox, ConditionalField } = typedFormFields<CreateDataRequestDto>();

export const EnergyTypesConsumption = () => {
  const { t } = useTranslation('dataRequest');
  const options: FormCheckboxType[] = [
    {
      label: t(`energy_type_consumption.${EnergyType.Electricity}`),
      value: EnergyType.Electricity,
    },
    {
      label: t(`energy_type_consumption.${EnergyType.Gas}`),
      value: EnergyType.Gas,
    },
  ];

  return (
    <ConditionalField
      field="energyTypesConsumption"
      condition={formValue => {
        return !!formValue('dataTypes') && formValue('dataTypes')!.includes(DataType.Consumption);
      }}
    >
      <FormCheckbox
        name="energyTypesConsumption"
        label={t('energy_types_consumption')}
        options={options}
        rules={{ required: true }}
      />
    </ConditionalField>
  );
};
