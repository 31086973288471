/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Edsn.WebApi.PiE
 * OpenAPI spec version: 1.0
 */

export type EnergyType = (typeof EnergyType)[keyof typeof EnergyType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EnergyType = {
  Electricity: 'Electricity',
  Gas: 'Gas',
} as const;
