import { useTranslation } from 'react-i18next';
import type { CreateDataRequestDto } from '#edsn/api/pie-bff';
import { DataLevel } from '#edsn/api/pie-bff';
import { typedFormFields } from '#pie/utils/typedFormFields';

const { ConditionalField, FormText } = typedFormFields<CreateDataRequestDto>();

export const LevelCity = () => {
  const { t } = useTranslation('dataRequest');
  return (
    <ConditionalField field="levelNote" condition={fieldValue => fieldValue('level') === DataLevel.City}>
      <FormText
        label={t('city_level')}
        name="levelNote"
        rules={{ maxLength: 200, minLength: 2, required: true }}
        fullWidth
      />
    </ConditionalField>
  );
};
