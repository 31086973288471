/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Edsn.WebApi.PiE
 * OpenAPI spec version: 1.0
 */

export type AssetType = (typeof AssetType)[keyof typeof AssetType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AssetType = {
  CablesOrPipes: 'CablesOrPipes',
  Connections: 'Connections',
  AgeCategoriesGasNet: 'AgeCategoriesGasNet',
  InfluenceRequests: 'InfluenceRequests',
  Other: 'Other',
} as const;
