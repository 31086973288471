/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Edsn.WebApi.PiE
 * OpenAPI spec version: 1.0
 */

export type AddressLevel = (typeof AddressLevel)[keyof typeof AddressLevel];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AddressLevel = {
  Individual: 'Individual',
  Cluster: 'Cluster',
} as const;
