import { useTranslation } from 'react-i18next';
import { type CreateDataRequestDto, SimpleAnswer } from '#edsn/api/pie-bff';
import { typedFormFields } from '#pie/utils/typedFormFields';

const { FormTextArea, ConditionalField } = typedFormFields<CreateDataRequestDto>();

export const CanObtainOtherwiseNote = () => {
  const { t } = useTranslation('dataRequest');

  return (
    <ConditionalField
      field="canObtainOtherwiseNote"
      condition={formValue => {
        return formValue('canObtainOtherwise') === SimpleAnswer.Yes;
      }}
    >
      <FormTextArea
        name="canObtainOtherwiseNote"
        label={t('can_obtain_otherwise.explanation')}
        rules={{ maxLength: 2000, minLength: 2, required: true }}
        fullWidth
      />
    </ConditionalField>
  );
};
