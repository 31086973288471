import { useTranslation } from 'react-i18next';
import { type CreateDataRequestDto, LegalBasisPersonalDataReason } from '#edsn/api/pie-bff';
import { typedFormFields } from '#pie/utils/typedFormFields';

export const PersonalDataLegalBasisReasonNote = () => {
  const { t } = useTranslation('dataRequest');
  const { FormTextArea, ConditionalField } = typedFormFields<CreateDataRequestDto>();

  return (
    <ConditionalField
      field="legalBasisPersonalDataReasonNote"
      condition={formValue => {
        return (
          formValue('legalBasisPersonalDataReason')! &&
          formValue('legalBasisPersonalDataReason') === LegalBasisPersonalDataReason.LegitimateInterest
        );
      }}
    >
      <FormTextArea
        aria-label={t('legal_basis_personal_data.note.note')}
        name="legalBasisPersonalDataReasonNote"
        label={t('legal_basis_personal_data.note')}
        fullWidth
        rules={{ maxLength: 200, minLength: 2, required: true }}
      />
    </ConditionalField>
  );
};
