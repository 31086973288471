import { useOidc, useOidcAccessToken } from '@axa-fr/react-oidc';
import { useQueryClient } from '@tanstack/react-query';
import { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { type AccountAuthorisationDto, fetchOptions, useGetUserOrganisationInformation } from '#edsn/api/pie-bff';
import type { PropsWithChildren } from 'react';
import { AuthLoading } from '#pie/components/auth-loading/AuthLoading';
import { Button } from '#pie/components/button/Button';
import { ErrorPage } from '#pie/pages/error-page/ErrorPage';

export interface OrganisationContext {
  currentAccount: AccountAuthorisationDto | undefined;
  setCurrentAccount: (account: AccountAuthorisationDto) => void;
}

const OrganisationContext = createContext<OrganisationContext | undefined>(undefined);

interface Props extends PropsWithChildren {
  // NOTE: To be used in tests
  renderChildrenOnError?: boolean;
}

export const OrganisationProvider = ({ children, renderChildrenOnError = false }: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { logout } = useOidc();
  const { data: accounts, isLoading } = useGetUserOrganisationInformation();
  const { accessTokenPayload } = useOidcAccessToken();
  const dvtTokenEan = accessTokenPayload?.organization?.marketParticipants[0]?.ean13;
  const [currentAccount, _setCurrentAccount] = useState<AccountAuthorisationDto | undefined>();

  const setCurrentAccount = (account?: AccountAuthorisationDto) => {
    fetchOptions.getOrganisationEan = () => account?.ean13 ?? null;
    _setCurrentAccount(account);
  };

  // Clear current account when there are no accounts (this is handled elsewhere)
  // Set first account in list as current account when:
  // - There is no current account
  // - The current account is not in the list
  useEffect(() => {
    if (accounts && !currentAccount) {
      if (!accounts.totalCount) {
        setCurrentAccount(undefined);
      } else if (dvtTokenEan && !currentAccount) {
        const accountFromToken = accounts.items.find(m => m.ean13 === dvtTokenEan);
        setCurrentAccount(accountFromToken ?? accounts.items[0]);
      }
    }
  }, [accounts, accessTokenPayload, currentAccount]);

  useEffect(() => {
    queryClient.clear();
    navigate(window.location, { replace: true });
  }, [currentAccount]);

  return (
    <OrganisationContext.Provider
      value={{
        currentAccount: isLoading ? undefined : currentAccount,
        setCurrentAccount: (newCurrentAccount: AccountAuthorisationDto) => {
          const account = accounts?.items.find(m => m.ean13 === newCurrentAccount.ean13);
          if (account) setCurrentAccount(account);
        },
      }}
    >
      {isLoading ? (
        <AuthLoading />
      ) : !currentAccount && !renderChildrenOnError ? (
        <ErrorPage
          heading="Geen toegang"
          message={
            <div>
              Er zijn geen organisaties gekoppeld aan de ingelogde gebruiker. Klik{' '}
              <Button
                variant="link"
                aria-label="Uitloggen"
                /* c8 ignore start */
                onClick={() => logout()}
                /* c8 ignore end */
                size="sm"
              >
                hier
              </Button>{' '}
              om uit te loggen.
            </div>
          }
        />
      ) : (
        children
      )}
    </OrganisationContext.Provider>
  );
};

export const useCurrentOrganisation = () => {
  const context = useContext(OrganisationContext);
  if (context === undefined) {
    throw new Error('useCurrentOrganisation must be used within a Organisation context');
  }
  return context;
};
