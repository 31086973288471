import { useTranslation } from 'react-i18next';
import type { CreateDataRequestDto } from '#edsn/api/pie-bff';
import { AddressLevel } from '#edsn/api/pie-bff';
import { CraftAssetDownload } from '#pie/components/craft-asset-download/CraftAssetDownload';
import { typedFormFields } from '#pie/utils/typedFormFields';

const { ConditionalField, FormFile } = typedFormFields<CreateDataRequestDto>();

export const CustomerMandateFile = () => {
  const { t } = useTranslation('dataRequest');
  return (
    <ConditionalField
      field="customerMandateFile"
      condition={fieldValue => fieldValue('addressLevel') === AddressLevel.Individual}
    >
      <FormFile name="customerMandateFile" label={t('customer_mandate_file')} rules={{ required: true }} />
      <CraftAssetDownload title="Voorbeeld toestemmingsformulier klantmandaat" />
    </ConditionalField>
  );
};
