import { useTranslation } from 'react-i18next';
import { Answer, type CreateDataRequestDto, LegalObligationReason } from '#edsn/api/pie-bff';
import { typedFormFields } from '#pie/utils/typedFormFields';

const { FormRadio, FormText, ConditionalField } = typedFormFields<CreateDataRequestDto>();

export const LegalObligationReasons = () => {
  const { t } = useTranslation('dataRequest');

  const legalObligationReasonOptions = [
    {
      label: t(`legal_obligation_reason.${LegalObligationReason.MunicipalLaw}`),
      value: LegalObligationReason.MunicipalLaw,
    },
    {
      label: t(`legal_obligation_reason.${LegalObligationReason.PoliceLaw}`),
      value: LegalObligationReason.PoliceLaw,
    },
    {
      label: t(`legal_obligation_reason.${LegalObligationReason.CriminalProcedure}`),
      value: LegalObligationReason.CriminalProcedure,
    },
    {
      label: t(`legal_obligation_reason.${LegalObligationReason.GeneralTaxLaw}`),
      value: LegalObligationReason.GeneralTaxLaw,
    },
    {
      label: t(`legal_obligation_reason.${LegalObligationReason.CivilProcedure}`),
      value: LegalObligationReason.CivilProcedure,
    },
    {
      label: t(`legal_obligation_reason.${LegalObligationReason.Suwi}`),
      value: LegalObligationReason.Suwi,
    },
    {
      label: t(`legal_obligation_reason.${LegalObligationReason.EcCompetionClaimsLaw}`),
      value: LegalObligationReason.EcCompetionClaimsLaw,
    },
    {
      label: t(`legal_obligation_reason.${LegalObligationReason.Guardianship}`),
      value: LegalObligationReason.Guardianship,
    },
    {
      label: t(`legal_obligation_reason.${LegalObligationReason.Wibon}`),
      value: LegalObligationReason.Wibon,
    },
    {
      label: t(`legal_obligation_reason.${LegalObligationReason.Other}`),
      renderInput: (
        <ConditionalField
          field="legalObligationReasonNote"
          condition={formValue => formValue('legalObligationReason') === LegalObligationReason.Other}
        >
          <FormText
            aria-label={t('legal_obligation_reason.note')}
            name={'legalObligationReasonNote'}
            rules={{ maxLength: 25, minLength: 2, required: true }}
            required
          />
        </ConditionalField>
      ),
      value: LegalObligationReason.Other,
    },
  ];

  return (
    <ConditionalField
      field="legalObligationReason"
      condition={formValue => formValue('legalObligation') === Answer.Yes}
    >
      <FormRadio
        name="legalObligationReason"
        label={t('legal_obligation_reason')}
        options={legalObligationReasonOptions}
        rules={{ required: true }}
      />
    </ConditionalField>
  );
};
