import { useTranslation } from 'react-i18next';
import { type CreateDataRequestDto, SimpleAnswer } from '#edsn/api/pie-bff';
import { typedFormFields } from '#pie/utils/typedFormFields';

const { FormRadio } = typedFormFields<CreateDataRequestDto>();

export const CanObtainOtherwise = () => {
  const { t } = useTranslation('dataRequest');
  const yesNoOptions = [
    {
      label: t(`simple_answer.${SimpleAnswer.Yes}`),
      value: SimpleAnswer.Yes,
    },
    {
      label: t(`simple_answer.${SimpleAnswer.No}`),
      value: SimpleAnswer.No,
    },
  ];

  return (
    <FormRadio
      name="canObtainOtherwise"
      label={t('can_obtain_otherwise')}
      options={yesNoOptions}
      infoTooltip={t('can_obtain_otherwise.tooltip')}
      rules={{ required: true }}
    />
  );
};
