/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Edsn.WebApi.PiE
 * OpenAPI spec version: 1.0
 */

export type DataLevel = (typeof DataLevel)[keyof typeof DataLevel];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DataLevel = {
  National: 'National',
  ZipCodeRegionalGridOperator: 'ZipCodeRegionalGridOperator',
  Province: 'Province',
  Municipality: 'Municipality',
  City: 'City',
  ResRegion: 'ResRegion',
  Address: 'Address',
  ZipCode: 'ZipCode',
  Other: 'Other',
} as const;
