import { Fragment } from 'react';
import { useController } from 'react-hook-form';
import { useExtendedRules } from '../formHelpers';
import type { FormCheckboxType } from '../types';
import type { ComponentProps } from 'react';
import type { FieldValues, UseControllerProps } from 'react-hook-form';
import { InputCheckbox } from '#pie/components/input-checkbox/InputCheckbox';
import { InputField } from '#pie/components/input-field/InputField';
import { Stack } from '#pie/components/stack/Stack';

type FormCheckboxProps<T extends FieldValues> = {
  options: FormCheckboxType[];
} & Pick<UseControllerProps<T>, 'disabled' | 'name' | 'rules' | 'defaultValue'> &
  Pick<ComponentProps<typeof InputField>, 'label' | 'infoTooltip'>;

export function FormCheckbox<T extends FieldValues>({
  name,
  disabled,
  rules: originalRules,
  defaultValue,
  label,
  options,
  ...props
}: FormCheckboxProps<T>) {
  const rules = useExtendedRules(originalRules);
  const {
    fieldState: { error },
    field: { onChange, ...field },
  } = useController({
    defaultValue,
    disabled,
    name,
    rules,
  });

  return (
    <InputField name={name} isRequired={!!rules?.required} label={label} error={error?.message} {...props}>
      {/* c8 ignore next */}
      <Stack gap={options.map(o => o.card) ? 'sm' : undefined}>
        {options.map(({ renderInput, ...option }) => (
          <Fragment key={`${name}-${option.value}`}>
            <InputCheckbox
              id={`${name}-${option.value}`}
              isError={!!error}
              disabled={disabled}
              onChange={value => {
                value && !field.value?.includes(option.value)
                  ? onChange([...(field.value || []), option.value])
                  : !value &&
                    field.value?.includes(option.value) &&
                    onChange(field.value?.filter((v: string) => v !== option.value));
              }}
              checked={field.value?.includes(option.value)}
              {...field}
              {...option}
            />
            {renderInput}
          </Fragment>
        ))}
      </Stack>
    </InputField>
  );
}
