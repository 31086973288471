import { useTranslation } from 'react-i18next';
import { type CreateDataRequestDto, DataType, EnergyType } from '#edsn/api/pie-bff';
import type { FormCheckboxType } from '#pie/components/form/types';
import { typedFormFields } from '#pie/utils/typedFormFields';

const { FormCheckbox, ConditionalField } = typedFormFields<CreateDataRequestDto>();

export const EnergyTypes = () => {
  const { t } = useTranslation('dataRequest');
  const options: FormCheckboxType[] = [
    {
      label: t(`energy_type.${EnergyType.Electricity}`),
      value: EnergyType.Electricity,
    },
    {
      label: t(`energy_type.${EnergyType.Gas}`),
      value: EnergyType.Gas,
    },
  ];

  return (
    <ConditionalField
      field="energyTypes"
      condition={formValue => {
        return !!formValue('dataTypes') && formValue('dataTypes')!.includes(DataType.Asset);
      }}
    >
      <FormCheckbox name="energyTypes" label={t('energy_types')} options={options} rules={{ required: true }} />
    </ConditionalField>
  );
};
