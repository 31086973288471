import { Icon, type IconProps } from '../icon/Icon';
import type { PolymorphicProps } from '#pie/constants/interfaces';
import type { ElementType, ReactNode } from 'react';
import type { Link } from 'react-router-dom';
import { type VariantProps, tv } from '#pie/utils/TailwindUtils';

export type ButtonProps<TComponent extends ElementType = typeof Link> = {
  iconStart?: IconProps['icon'];
  children?: string | ReactNode;
  iconEnd?: IconProps['icon'];
} & PolymorphicProps<TComponent, VariantProps<typeof button>>;

export const sizeMapper = {
  lg: 24,
  md: 24,
  sm: 16,
} satisfies Record<'lg' | 'md' | 'sm', IconProps['size']>;

export function Button<TComponent extends ElementType = 'button'>({
  as,
  children,
  variant,
  className,
  isLoading,
  to,
  iconStart,
  iconEnd,
  isDisabled,
  size,
  ...props
}: ButtonProps<TComponent>) {
  const Component = as ?? 'button';
  const styles = button({ isDisabled, isLoading, size, variant });

  return (
    <Component
      to={to}
      className={styles.base({ className })}
      disabled={isDisabled || isLoading}
      type={Component === 'button' ? 'button' : undefined}
      {...props}
    >
      {!!isLoading && (
        <div className="absolute animate-spin" role="progressbar">
          <div className={styles.loader()} />
        </div>
      )}

      {!!iconStart && <Icon icon={iconStart} size={sizeMapper[size ?? 'lg']} className={styles.icon()} />}

      {!!children && <span className={styles.childrenWrapper()}>{children}</span>}

      {!!iconEnd && <Icon icon={iconEnd} size={sizeMapper[size ?? 'lg']} className={styles.icon()} />}
    </Component>
  );
}

export const button = tv({
  base: 'relative inline-flex items-center justify-center rounded bg-transparent text-white gap-2 border border-transparent whitespace-nowrap',
  defaultVariants: {
    size: 'lg',
    variant: 'primary',
  },
  slots: {
    childrenWrapper: 'inline-flex items-center justify-center',
    icon: 'inline-block',
    loader:
      'absolute left-1/2 top-1/2 hidden h-4 w-4 -translate-x-1/2 -translate-y-1/2 transform rounded-full border-2 border-action border-t-white',
  },
  variants: {
    isDisabled: {
      true: 'pointer-events-none opacity-50',
    },
    isLoading: {
      true: {
        childrenWrapper: 'invisible',
        icon: 'invisible',
        loader: 'block',
      },
    },
    size: {
      lg: 'px-6 py-3',
      md: 'px-4 py-1.5',
      sm: 'px-4 py-1.5',
    },
    variant: {
      // TODO: Add different states of danger button
      danger: 'bg-error border-error',
      ghost: {
        base: 'bg-white text-black border-action hover:bg-neutral-200',
        icon: 'text-secondary',
      },
      ghostNeutral: 'bg-white border-neutral-500 text-neutral-600 hover:border-neutral-200',
      ghostPrimary: 'bg-white border-white text-primary hover:bg-neutral-200 hover:border-neutralaction',
      ghostPrimaryLight: 'text-primary-light hover:bg-neutral-200 hover:border-neutralaction',
      grey: {
        base: 'bg-neutral-200 text-neutral-600 group border hover:border-neutral-500',
        icon: 'text-neutral-600 group-hover:text-secondary',
      },
      icon: {
        base: 'bg-white text-black border-neutral-500 hover:border-action p-3',
        icon: '',
      },
      link: { base: 'link text-link p-0 inline space-x-1', childrenWrapper: 'inline', icon: 'align-top' },
      linkPrimary: { base: 'link text-primary p-0 inline space-x-1', childrenWrapper: 'inline', icon: 'align-top' },
      linkSecondary: { base: 'link text-secondary p-0 inline space-x-1', childrenWrapper: 'inline', icon: 'align-top' },
      primary: 'bg-primary border-primary hover:bg-primary-light hover:border-primary-light',
      secondary: 'bg-secondary border-secondary hover:bg-secondary-dark hover:border-secondary-dark',
    },
  },
});
