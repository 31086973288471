/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Edsn.WebApi.PiE
 * OpenAPI spec version: 1.0
 */

export type FileFormat = (typeof FileFormat)[keyof typeof FileFormat];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FileFormat = {
  Csv: 'Csv',
  Shape: 'Shape',
  Other: 'Other',
} as const;
