/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Edsn.WebApi.PiE
 * OpenAPI spec version: 1.0
 */

export type LegalObligationReason = (typeof LegalObligationReason)[keyof typeof LegalObligationReason];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LegalObligationReason = {
  MunicipalLaw: 'MunicipalLaw',
  PoliceLaw: 'PoliceLaw',
  CriminalProcedure: 'CriminalProcedure',
  GeneralTaxLaw: 'GeneralTaxLaw',
  CivilProcedure: 'CivilProcedure',
  Suwi: 'Suwi',
  Cbs: 'Cbs',
  EcCompetionClaimsLaw: 'EcCompetionClaimsLaw',
  Guardianship: 'Guardianship',
  Wibon: 'Wibon',
  Other: 'Other',
} as const;
