import { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { useGetMainCategory, usePostTicket } from '#edsn/api/pie-bff';
import { Stepper } from '../../../components/stepper/Stepper';
import { TicketCreateNonConformityCodes, schema as codesSchema } from './TicketCreateNonConformityCodes';
import { TicketCreateNonConformityDetails, schema as detailsSchema } from './TicketCreateNonConformityDetails';
import { TicketCreateNonConformityTickets } from './TicketCreateNonConformityTickets';
import type { ReactNode } from 'react';
import { Button } from '#pie/components/button/Button';
import { CancelCreateTicketDialog } from '#pie/components/cancel-create-ticket-dialog/CancelCreateTicketDialog';
import { InputCheckbox } from '#pie/components/input-checkbox/InputCheckbox';
import { PageHead } from '#pie/components/page-head/PageHead';
import { Stack } from '#pie/components/stack/Stack';
import { ToastButton } from '#pie/components/toast/Toast';
import { useToast } from '#pie/components/toast/ToastContext';
import { VITE_NON_CONFORMITY_CATEGORY_ID } from '#pie/env';
import { useStoredParams } from '#pie/stores/searchParams';

export const nonConformitySchema = z
  .object({
    measureField: z.string(),
    observationDate: z.string(),
    placeName: z.string().optional(),
    rejections: z
      .array(
        z.object({
          rejectionCategoryId: z.string(),
          rejectionReasonId: z.string().nullable(),
        })
      )
      .min(1),
  })
  .merge(codesSchema())
  .merge(detailsSchema);

export type PartialNonConformitySchema = Partial<z.infer<typeof nonConformitySchema>>;
interface NonConformityFormContext {
  data: PartialNonConformitySchema;
  setStepShouldBlock: (stepShouldBlock: boolean) => void;
  setIsLoading: (isLoading: boolean) => void;
}

export const NonConformityFormContext = createContext<NonConformityFormContext | undefined>(undefined);

export const useNonConformityFormContext = () => {
  const context = useContext(NonConformityFormContext);
  if (!context) {
    throw new Error('useEanFormContext must be used within a NonConformityFormContext');
  }
  return context;
};

export interface StepProps {
  wizardActions: ReactNode;
  stepper: ReactNode;
  onSubmit: (data: PartialNonConformitySchema) => void;
  onBack: () => void;
}

export const useNonConformityCategory = () => {
  const { data, isLoading } = useGetMainCategory();

  const mainCategory = data?.items.find(c => c.id === VITE_NON_CONFORMITY_CATEGORY_ID);
  const subCategories = mainCategory?.subCategories.at(0);

  return { isLoading, mainCategoryId: mainCategory?.id, subCategoryId: subCategories?.id };
};

export const TicketCreateNonConformity = ({ defaultValues }: { defaultValues?: PartialNonConformitySchema }) => {
  const { t } = useTranslation();

  const params = useStoredParams('/tickets');

  const [step, setStep] = useState(0);
  const [shouldBlock, setShouldBlock] = useState<[boolean, boolean, boolean]>([false, false, false]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<PartialNonConformitySchema>({
    rejections: [{ rejectionCategoryId: '' }],
    ...defaultValues,
  });
  const [createAnother, setCreateAnother] = useState<boolean>(false);
  const title = `NC-${data.electricityEan}`;

  const stepComponents = [
    TicketCreateNonConformityCodes,
    TicketCreateNonConformityTickets,
    TicketCreateNonConformityDetails,
  ] as const;
  const Step = stepComponents[step];

  const navigate = useNavigate();
  const { addToast } = useToast();

  const { subCategoryId, mainCategoryId, isLoading: isCategoryLoading } = useNonConformityCategory();

  const {
    mutate,
    isLoading: isPostLoading,
    isSuccess,
  } = usePostTicket({
    mutation: {
      onError() {
        addToast({
          message: t('ticket_create.toast.create_error.message'),
          title: t('ticket_create.toast.create_error.title'),
          type: 'error',
        });
      },
      onSuccess: ({ id }) => {
        addToast({
          action: (
            <ToastButton as={Link} to={`/tickets/${id}`}>
              {t('ticket_create.toast.create_success.action')}
            </ToastButton>
          ),
          message: t('ticket_create.toast.create_success.message', { title }),
          title: t('ticket_create.toast.create_success.title'),
          type: 'success',
        });
      },
    },
  });

  const onSubmit = async (stepData: PartialNonConformitySchema) => {
    const mergedData = { ...data, ...stepData };
    setData(mergedData);

    if (step < stepComponents.length - 1) {
      setStep(step + 1);
    } else {
      const submitData = mergedData;
      const { files, placeName, measureField, rejections, observationDate, ...ticketData } =
        nonConformitySchema.parse(submitData);

      mutate({
        data: {
          ...ticketData,
          attachments: files?.filter(({ file }) => file).map(({ file }) => file),
          mainCategoryId: mainCategoryId!,
          nonConformityDetails: {
            measureField: measureField,
            observationDate: observationDate,
            placeName: placeName,
            rejections: rejections!,
          },
          subCategoryId: subCategoryId!,
          title,
        },
      });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      navigate(createAnother ? '/tickets/nieuw' : '/tickets');
    }
  }, [createAnother, isSuccess, navigate]);

  return (
    <>
      <PageHead title={t('ticket_create.page_title')} />
      <NonConformityFormContext.Provider
        value={{
          data,
          setIsLoading,
          setStepShouldBlock: stepShouldBlock => {
            const toReturn = [...shouldBlock] satisfies typeof shouldBlock;
            toReturn[step] = stepShouldBlock;
            setShouldBlock(toReturn);
          },
        }}
      >
        <Step
          onBack={() => setStep(step - 1)}
          onSubmit={onSubmit}
          stepper={
            <div className="-mx-6 mt-4 flex justify-center border-t border-t-neutral-200 px-6 pt-3">
              <Stepper
                steps={[
                  {
                    children: t('ticket_create.steps.codes'),
                  },
                  {
                    children: t('ticket_create.steps.tickets'),
                  },
                  {
                    children: t('ticket_create.steps.details'),
                  },
                ].map((s, index) => ({
                  ...s,
                  active: index === step,
                  done: index < step,
                  onClick: index < step ? () => setStep(index) : undefined,
                }))}
              />
            </div>
          }
          wizardActions={
            step === stepComponents.length - 1 ? (
              <>
                <Button as={Link} to={`/tickets${params}`} variant="ghost">
                  Annuleren
                </Button>
                <Stack direction="row" className="items-center" gap="lg">
                  <InputCheckbox
                    label={t('ticket_create.footer.create_another')}
                    name="createAnother"
                    onCheckedChange={value => {
                      setCreateAnother(value === true);
                    }}
                  />
                  <Button type="submit" variant="secondary" isLoading={isPostLoading || isCategoryLoading || isLoading}>
                    {t('ticket_create.footer.submit')}
                  </Button>
                </Stack>
              </>
            ) : (
              <>
                <Button as={Link} to={`/tickets${params}`} variant="ghost">
                  {t('common.button.cancel')}
                </Button>
                <Button type="submit" variant="secondary" isLoading={isPostLoading || isLoading}>
                  {t('common.steps.next')}
                </Button>
              </>
            )
          }
        />
        <CancelCreateTicketDialog shouldBlock={shouldBlock.some(step => step) && !isSuccess} />
      </NonConformityFormContext.Provider>
    </>
  );
};
