import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import type { MarketRole } from '#edsn/api/pie-bff';
import { getCprContactFilters, getCprOrganisations } from '#edsn/api/pie-bff';
import { Card } from '#pie/components/card/Card';
import { CopyButton } from '#pie/components/copy-button/CopyButton';
import { DataFilters } from '#pie/components/data-filters/DataFilters';
import { DataTable } from '#pie/components/data-table/DataTable';
import { DataToolbar } from '#pie/components/data-toolbar/DataToolbar';
import { Stack } from '#pie/components/stack/Stack';
import { useDataView } from '#pie/hooks/useDataView';
import { useMarketRoleOptions } from '#pie/hooks/useMarketRoleOptions';
import { cprOrganisationFiltersAtom, cprOrganisationSortingAtom } from '#pie/stores/cprOrganisation';
import { mapToFilterOptions } from '#pie/utils/mapToFilterOptions';

export const ContactPersonRegisterOrganisation = () => {
  const { t } = useTranslation();

  const marketRoles = useMarketRoleOptions();
  const { tableProps, filterProps, toolbarProps } = useDataView({
    columns: helper => [
      helper.accessor('marketParty.name', {
        header: t('common.organisation'),
        meta: { columnWidth: 500 },
      }),
      helper.accessor('organisation.name', {
        cell: info => (
          <Link
            className="text-primary link"
            to={info.row.original.organisation.id}
            aria-label={info.getValue()}
            data-title={info.getValue()}
          >
            {info.getValue()}
          </Link>
        ),
        header: t('common.name.organisationIds'),
        meta: { columnWidth: 500 },
      }),
      helper.accessor('organisation.ean13', {
        cell: info => <CopyButton content={info.getValue()}>{info.getValue()}</CopyButton>,
        header: t('common.ean13'),
      }),
      helper.accessor('marketParty.marketRole', {
        cell: info => t(`common.market_role.abbreviation.${info.getValue() as MarketRole}`),
        header: t('common.market_role'),
      }),
    ],
    dateKeys: [],
    filters: [
      {
        id: 'marketPartyIds',
        label: t('common.filter.marketPartyIds'),
        options: data => mapToFilterOptions(data?.marketParties),
      },
      {
        id: 'marketRoles',
        label: t('common.filter.marketRoles'),
        options: marketRoles,
      },
    ],
    filtersAtom: cprOrganisationFiltersAtom,
    filtersQuery: getCprContactFilters,
    query: getCprOrganisations,
    sortingAtom: cprOrganisationSortingAtom,
  });

  return (
    <section className="flex items-start gap-6">
      <Card className="flex-1 overflow-x-hidden p-4">
        <Stack gap="md">
          <DataToolbar {...toolbarProps} />
          <DataTable
            rowLinks={row => ({ 'aria-label': row.original.organisation.name, to: row.original.organisation.id })}
            {...tableProps}
            idKey="organisation.id"
          />
        </Stack>
      </Card>
      <DataFilters {...filterProps} />
    </section>
  );
};
