/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Edsn.WebApi.PiE
 * OpenAPI spec version: 1.0
 */

export type Answer = (typeof Answer)[keyof typeof Answer];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Answer = {
  Yes: 'Yes',
  No: 'No',
  DontKnow: 'DontKnow',
} as const;
