import { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { usePostTicket } from '#edsn/api/pie-bff';
import { Stepper } from '../../../components/stepper/Stepper';
import { TicketCreateEanCodes, schema as codesSchema } from './TicketCreateEanCodes';
import { TicketCreateEanDetails, schema as detailsSchema } from './TicketCreateEanDetails';
import { TicketCreateEanTickets } from './TicketCreateEanTickets';
import type { ReactNode } from 'react';
import { Button } from '#pie/components/button/Button';
import { CancelCreateTicketDialog } from '#pie/components/cancel-create-ticket-dialog/CancelCreateTicketDialog';
import { InputCheckbox } from '#pie/components/input-checkbox/InputCheckbox';
import { PageHead } from '#pie/components/page-head/PageHead';
import { Stack } from '#pie/components/stack/Stack';
import { ToastButton } from '#pie/components/toast/Toast';
import { useToast } from '#pie/components/toast/ToastContext';
import { useStoredParams } from '#pie/stores/searchParams';

export const eanSchema = (t: ReturnType<typeof useTranslation>['t']) =>
  z.object({}).merge(codesSchema()).merge(detailsSchema);

export type PartialEanSchema = Partial<z.infer<ReturnType<typeof eanSchema>>>;
interface EanFormContext {
  data: PartialEanSchema;
  setStepShouldBlock: (stepShouldBlock: boolean) => void;
  setIsLoading: (isLoading: boolean) => void;
}

export const EanFormContext = createContext<EanFormContext | undefined>(undefined);

export const useEanFormContext = () => {
  const context = useContext(EanFormContext);
  if (!context) {
    throw new Error('useEanFormContext must be used within a EanFormContext');
  }
  return context;
};

export interface StepProps {
  wizardActions: ReactNode;
  stepper: ReactNode;
  onSubmit: (data: PartialEanSchema) => void;
  onBack: () => void;
}

export const TicketCreateEan = () => {
  const { t } = useTranslation();

  const params = useStoredParams('/tickets');

  const [step, setStep] = useState(0);
  const [shouldBlock, setShouldBlock] = useState<[boolean, boolean, boolean]>([false, false, false]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<PartialEanSchema>({});
  const [createAnother, setCreateAnother] = useState<boolean>(false);

  const stepComponents = [TicketCreateEanCodes, TicketCreateEanTickets, TicketCreateEanDetails] as const;
  const Step = stepComponents[step];

  const navigate = useNavigate();
  const { addToast } = useToast();

  const {
    mutate,
    isLoading: isPostLoading,
    isSuccess,
  } = usePostTicket({
    mutation: {
      onError() {
        addToast({
          message: t('ticket_create.toast.create_error.message'),
          title: t('ticket_create.toast.create_error.title'),
          type: 'error',
        });
      },
      onSuccess: ({ id }) => {
        addToast({
          action: (
            <ToastButton as={Link} to={`/tickets/${id}`}>
              {t('ticket_create.toast.create_success.action')}
            </ToastButton>
          ),
          message: t('ticket_create.toast.create_success.message', { title: data.title }),
          title: t('ticket_create.toast.create_success.title'),
          type: 'success',
        });
      },
    },
  });

  const onSubmit = async (stepData: PartialEanSchema) => {
    const mergedData = { ...data, ...stepData };
    setData(mergedData);

    if (step < stepComponents.length - 1) {
      setStep(step + 1);
    } else {
      const submitData = mergedData;
      const { files, eventDate, ...ticketData } = eanSchema(t).parse(submitData);

      mutate({
        data: {
          ...ticketData,
          attachments: files?.filter(({ file }) => file).map(({ file }) => file),
          eventDate: eventDate?.toString(),
        },
      });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      navigate(createAnother ? '/tickets/nieuw' : '/tickets');
    }
  }, [createAnother, isSuccess, navigate]);

  return (
    <>
      <PageHead title={t('ticket_create.page_title')} />
      <EanFormContext.Provider
        value={{
          data,
          setIsLoading,
          setStepShouldBlock: stepShouldBlock => {
            const toReturn = [...shouldBlock] satisfies typeof shouldBlock;
            toReturn[step] = stepShouldBlock;
            setShouldBlock(toReturn);
          },
        }}
      >
        <Step
          onBack={() => setStep(step - 1)}
          onSubmit={onSubmit}
          stepper={
            <div className="-mx-6 mt-4 flex justify-center border-t border-t-neutral-200 px-6 pt-3">
              <Stepper
                steps={[
                  {
                    children: t('ticket_create.steps.codes'),
                  },
                  {
                    children: t('ticket_create.steps.tickets'),
                  },
                  {
                    children: t('ticket_create.steps.details'),
                  },
                ].map((s, index) => ({
                  ...s,
                  active: index === step,
                  done: index < step,
                  onClick: index < step ? () => setStep(index) : undefined,
                }))}
              />
            </div>
          }
          wizardActions={
            step === stepComponents.length - 1 ? (
              <>
                <Button as={Link} to={`/tickets${params}`} variant="ghost">
                  Annuleren
                </Button>
                <Stack direction="row" className="items-center" gap="lg">
                  <InputCheckbox
                    label={t('ticket_create.footer.create_another')}
                    name="createAnother"
                    onCheckedChange={value => {
                      setCreateAnother(value === true);
                    }}
                  />
                  <Button type="submit" variant="secondary" isLoading={isPostLoading || isLoading}>
                    {t('ticket_create.footer.submit')}
                  </Button>
                </Stack>
              </>
            ) : (
              <>
                <Button as={Link} to={`/tickets${params}`} variant="ghost">
                  {t('common.button.cancel')}
                </Button>
                <Button type="submit" variant="secondary" isLoading={isPostLoading || isLoading}>
                  {t('common.steps.next')}
                </Button>
              </>
            )
          }
        />
        <CancelCreateTicketDialog shouldBlock={shouldBlock.some(step => step) && !isSuccess} />
      </EanFormContext.Provider>
    </>
  );
};
