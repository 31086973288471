import { type FieldValues, type UseControllerProps, useController } from 'react-hook-form';
import { useExtendedRules } from '../formHelpers';
import type { ComponentProps } from 'react';
import { InputDate } from '#pie/components/input-date/InputDate';
import { InputField } from '#pie/components/input-field/InputField';

type FormDateProps<T extends FieldValues> = Omit<ComponentProps<typeof InputDate>, 'onChange' | 'required'> &
  Pick<UseControllerProps<T>, 'disabled' | 'name' | 'rules' | 'defaultValue'> &
  Pick<ComponentProps<typeof InputField>, 'label'> & { onChange?: (date: string) => void };

export function FormDate<T extends FieldValues>({
  name,
  disabled,
  rules: originalRules,
  defaultValue,
  label,
  ...props
}: FormDateProps<T>) {
  const rules = useExtendedRules(originalRules);
  const {
    fieldState: { error },
    field,
  } = useController({
    defaultValue,
    disabled,
    name,
    rules,
  });

  return (
    <InputField name={name} isRequired={!!rules?.required} label={label} error={error?.message}>
      <InputDate id={name} isError={!!error} disabled={disabled} {...field} {...props} />
    </InputField>
  );
}
