import { useTranslation } from 'react-i18next';
import type { MarketRole } from '#edsn/api/pie-bff';
import { getCprContact, getCprContactFilters } from '#edsn/api/pie-bff';
import { Button } from '#pie/components/button/Button';
import { Card } from '#pie/components/card/Card';
import { CopyButton } from '#pie/components/copy-button/CopyButton';
import { DataFilters } from '#pie/components/data-filters/DataFilters';
import { DataTable } from '#pie/components/data-table/DataTable';
import { DataToolbar } from '#pie/components/data-toolbar/DataToolbar';
import { Stack } from '#pie/components/stack/Stack';
import { useDataView } from '#pie/hooks/useDataView';
import { useMarketRoleOptions } from '#pie/hooks/useMarketRoleOptions';
import { cprContactFiltersAtom, cprContactSortingAtom } from '#pie/stores/cprContact';
import { mapToFilterOptions } from '#pie/utils/mapToFilterOptions';

export const ContactPersonRegisterContact = () => {
  const { t } = useTranslation();

  const marketRoles = useMarketRoleOptions();
  const { tableProps, filterProps, toolbarProps } = useDataView({
    columns: helper => [
      helper.accessor('name', {
        header: t('common.name'),
        meta: { columnWidth: 200 },
      }),
      helper.accessor('email', {
        cell: info => (
          <Button as="a" variant="linkPrimary" href={`mailto:${info.getValue()}`}>
            {info.getValue()}
          </Button>
        ),
        header: t('common.email'),
        meta: { columnWidth: 200 },
      }),
      helper.accessor('phone', {
        cell: info => (
          <Button as="a" variant="linkPrimary" href={`tel:${info.getValue()}`}>
            {info.getValue()}
          </Button>
        ),
        header: t('common.phone'),
      }),
      helper.accessor('contactGroup.label', {
        header: t('common.contact_group'),
        meta: { columnWidth: 250 },
      }),
      helper.accessor('marketParty.name', {
        header: t('common.organisation'),
        meta: { columnWidth: 250 },
      }),
      helper.accessor('organisation.name', {
        header: t('common.name.organisationIds'),
        meta: { columnWidth: 250 },
      }),
      helper.accessor('marketParty.ean13', {
        cell: info => <CopyButton content={info.getValue()}>{info.getValue()}</CopyButton>,
        header: t('common.ean13'),
      }),
      helper.accessor('marketParty.marketRole', {
        cell: info => t(`common.market_role.abbreviation.${info.getValue() as MarketRole}`),
        header: t('common.market_role'),
      }),
    ],
    dateKeys: [],
    filters: [
      {
        id: 'marketPartyIds',
        label: t('common.filter.marketPartyIds'),
        options: data => mapToFilterOptions(data?.marketParties),
      },
      {
        id: 'organisationIds',
        label: t('common.name.organisationIds'),
        options: data => mapToFilterOptions(data?.organisations),
      },
      {
        id: 'marketRoles',
        label: t('common.filter.marketRoles'),
        options: marketRoles,
      },
    ],
    filtersAtom: cprContactFiltersAtom,
    filtersQuery: getCprContactFilters,
    query: getCprContact,
    sortingAtom: cprContactSortingAtom,
  });

  return (
    <section className="flex items-start gap-6">
      <Card className="flex-1 overflow-x-hidden p-4">
        <Stack gap="md">
          <DataToolbar {...toolbarProps} />
          <DataTable {...tableProps} />
        </Stack>
      </Card>
      <DataFilters {...filterProps} />
    </section>
  );
};
