import { useTranslation } from 'react-i18next';
import type { FieldPath, FieldValues, RegisterOptions } from 'react-hook-form';

type RulesType<T extends FieldValues> = Omit<
  RegisterOptions<T, FieldPath<T>>,
  'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
>;
export function useExtendedRules<T extends FieldValues>(rules?: RulesType<T>): RulesType<T> | undefined {
  const { t } = useTranslation();

  return rules
    ? {
        ...rules,
        required: rules.required === true ? t('zod.errors.required') : rules.required,
      }
    : undefined;
}
