import arrowDown from '@iconify/icons-material-symbols/keyboard-double-arrow-down';
import arrowUp from '@iconify/icons-material-symbols/keyboard-double-arrow-up';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '../icon/Icon';
import { Stack } from '../stack/Stack';
import type { StackProps } from '../stack/Stack';
import type { ReactNode } from 'react';

export interface Props extends StackProps {
  children: ReactNode[];
  collapseAt: number;
}

export const CollapseStack = ({ children, collapseAt = 3, ...props }: Props) => {
  const { t } = useTranslation();
  const internalCollapseAt = collapseAt > 0 ? collapseAt : undefined;

  const shouldCollapseInitially = !!internalCollapseAt && children.length > internalCollapseAt;
  const [collapsed, setCollapsed] = useState(shouldCollapseInitially);

  return (
    <Stack gap="sm" {...props}>
      {children.slice(0, collapsed ? internalCollapseAt : children.length).map(option => option)}
      {shouldCollapseInitially && (
        <button className="ml-[-3px] flex appearance-none gap-1 text-left" onClick={() => setCollapsed(!collapsed)}>
          <Icon size={24} icon={collapsed ? arrowDown : arrowUp} className="text-primary" />{' '}
          {collapsed ? t('common.filter.show_more') : t('common.filter.show_fewer')}
        </button>
      )}
    </Stack>
  );
};
