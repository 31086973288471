/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Edsn.WebApi.PiE
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  AccountAuthorisationDtoListResultDto,
  AddContactToTeamDto,
  AttachmentDto,
  AttachmentDtoListResultDto,
  ClearCacheDto,
  CommentDtoListResultDto,
  ConnectionInformationDto,
  ContactDetailsDto,
  ContactDetailsDtoListResultDto,
  ContactDtoListResultDto,
  ContactFilterListRequestDto,
  CprContactDtoListResultDto,
  CprContactFilterListRequestDto,
  CprFiltersDto,
  CprManagementContactDtoListResultDto,
  CprManagementContactFilterListRequestDto,
  CprOrganisationDtoListResultDto,
  CprOrganisationFilterListRequestDto,
  CreateAttachmentDto,
  CreateCommentDto,
  CreateCprContactDto,
  CreateDataRequestDto,
  CreateResponseDto,
  CreateSubcategoryNotificationDto,
  CreateTicketDto,
  CreateUpdateContactDetailsDto,
  CreateUpdateContactDto,
  CreateUpdateTeamDto,
  Error,
  ExecuteMultipleResponseDto,
  GetAttachmentMetadataParams,
  GetCommentParams,
  GetConnectionInformationParams,
  GridOperatorDtoListResultDto,
  HttpValidationProblemDetails,
  KnowledgeArticleDetailDto,
  KnowledgeArticleFilterListRequestDto,
  KnowledgeArticleListDtoListResultDto,
  MainCategoryDtoListResultDto,
  ManagementContactFilterListRequestDto,
  NotificationDto,
  RejectionCategoryDtoListResultDto,
  SurveyDialogueDto,
  SurveyDialogueDtoListResultDto,
  SurveyDialogueFilterListRequestDto,
  SurveyResponseDto,
  TeamDetailsDto,
  TeamDtoListResultDto,
  TicketCallToActionDto,
  TicketFilterListRequestDto,
  TicketFiltersDto,
  TicketListDto,
  TicketListDtoListResultDto,
  UpdateContactInformationDto,
  UpdateNotificationDto,
  UpdateSurveyDialogueDto,
} from './model';
import { fetchInstance } from '../fetchInstance';
import postTicketMutator from '../hooks/useFormDataMutator';

export const postAttachment = (createAttachmentDto: CreateAttachmentDto) => {
  const formData = new FormData();
  formData.append('referenceType', createAttachmentDto.referenceType);
  formData.append('referenceId', createAttachmentDto.referenceId);
  formData.append('file', createAttachmentDto.file);

  return fetchInstance<AttachmentDto>({
    url: `/attachment`,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
  });
};

export const getPostAttachmentMutationOptions = <
  TError = HttpValidationProblemDetails | Error,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAttachment>>,
    TError,
    { data: CreateAttachmentDto },
    TContext
  >;
}): UseMutationOptions<Awaited<ReturnType<typeof postAttachment>>, TError, { data: CreateAttachmentDto }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postAttachment>>,
    { data: CreateAttachmentDto }
  > = props => {
    const { data } = props ?? {};

    return postAttachment(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostAttachmentMutationResult = NonNullable<Awaited<ReturnType<typeof postAttachment>>>;
export type PostAttachmentMutationBody = CreateAttachmentDto;
export type PostAttachmentMutationError = HttpValidationProblemDetails | Error;

export const usePostAttachment = <TError = HttpValidationProblemDetails | Error, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAttachment>>,
    TError,
    { data: CreateAttachmentDto },
    TContext
  >;
}): UseMutationResult<Awaited<ReturnType<typeof postAttachment>>, TError, { data: CreateAttachmentDto }, TContext> => {
  const mutationOptions = getPostAttachmentMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getAttachmentId = (id: string, signal?: AbortSignal) => {
  return fetchInstance<Blob>({ url: `/attachment/${id}`, method: 'GET', responseType: 'blob', signal });
};

export const getGetAttachmentIdQueryKey = (id: string) => {
  return [`/attachment/${id}`] as const;
};

export const getGetAttachmentIdQueryOptions = <TData = Awaited<ReturnType<typeof getAttachmentId>>, TError = Error>(
  id: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getAttachmentId>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAttachmentIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAttachmentId>>> = ({ signal }) =>
    getAttachmentId(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAttachmentId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAttachmentIdQueryResult = NonNullable<Awaited<ReturnType<typeof getAttachmentId>>>;
export type GetAttachmentIdQueryError = Error;

export const useGetAttachmentId = <TData = Awaited<ReturnType<typeof getAttachmentId>>, TError = Error>(
  id: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getAttachmentId>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAttachmentIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getAttachmentMetadata = (params: GetAttachmentMetadataParams, signal?: AbortSignal) => {
  return fetchInstance<AttachmentDtoListResultDto>({ url: `/attachment/metadata`, method: 'GET', params, signal });
};

export const getGetAttachmentMetadataQueryKey = (params: GetAttachmentMetadataParams) => {
  return [`/attachment/metadata`, ...(params ? [params] : [])] as const;
};

export const getGetAttachmentMetadataQueryOptions = <
  TData = Awaited<ReturnType<typeof getAttachmentMetadata>>,
  TError = Error,
>(
  params: GetAttachmentMetadataParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getAttachmentMetadata>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAttachmentMetadataQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAttachmentMetadata>>> = ({ signal }) =>
    getAttachmentMetadata(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAttachmentMetadata>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAttachmentMetadataQueryResult = NonNullable<Awaited<ReturnType<typeof getAttachmentMetadata>>>;
export type GetAttachmentMetadataQueryError = Error;

export const useGetAttachmentMetadata = <TData = Awaited<ReturnType<typeof getAttachmentMetadata>>, TError = Error>(
  params: GetAttachmentMetadataParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getAttachmentMetadata>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAttachmentMetadataQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postCacheClear = (clearCacheDto: ClearCacheDto) => {
  return fetchInstance<void>({
    url: `/cache/clear`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: clearCacheDto,
  });
};

export const getPostCacheClearMutationOptions = <
  TError = HttpValidationProblemDetails | Error,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postCacheClear>>, TError, { data: ClearCacheDto }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof postCacheClear>>, TError, { data: ClearCacheDto }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCacheClear>>, { data: ClearCacheDto }> = props => {
    const { data } = props ?? {};

    return postCacheClear(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostCacheClearMutationResult = NonNullable<Awaited<ReturnType<typeof postCacheClear>>>;
export type PostCacheClearMutationBody = ClearCacheDto;
export type PostCacheClearMutationError = HttpValidationProblemDetails | Error;

export const usePostCacheClear = <TError = HttpValidationProblemDetails | Error, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postCacheClear>>, TError, { data: ClearCacheDto }, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof postCacheClear>>, TError, { data: ClearCacheDto }, TContext> => {
  const mutationOptions = getPostCacheClearMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getComment = (params: GetCommentParams, signal?: AbortSignal) => {
  return fetchInstance<CommentDtoListResultDto>({ url: `/comment`, method: 'GET', params, signal });
};

export const getGetCommentQueryKey = (params: GetCommentParams) => {
  return [`/comment`, ...(params ? [params] : [])] as const;
};

export const getGetCommentQueryOptions = <
  TData = Awaited<ReturnType<typeof getComment>>,
  TError = HttpValidationProblemDetails | Error,
>(
  params: GetCommentParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getComment>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCommentQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getComment>>> = ({ signal }) => getComment(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getComment>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCommentQueryResult = NonNullable<Awaited<ReturnType<typeof getComment>>>;
export type GetCommentQueryError = HttpValidationProblemDetails | Error;

export const useGetComment = <
  TData = Awaited<ReturnType<typeof getComment>>,
  TError = HttpValidationProblemDetails | Error,
>(
  params: GetCommentParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getComment>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCommentQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postCommentCreate = (createCommentDto: CreateCommentDto) => {
  const formData = new FormData();
  formData.append('description', createCommentDto.description);
  formData.append('referenceId', createCommentDto.referenceId);
  formData.append('referenceType', createCommentDto.referenceType);
  if (createCommentDto.attachments !== undefined) {
    createCommentDto.attachments.forEach(value => formData.append('attachments', value));
  }

  return fetchInstance<CreateResponseDto>({
    url: `/comment/create`,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
  });
};

export const getPostCommentCreateMutationOptions = <
  TError = HttpValidationProblemDetails | Error,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCommentCreate>>,
    TError,
    { data: CreateCommentDto },
    TContext
  >;
}): UseMutationOptions<Awaited<ReturnType<typeof postCommentCreate>>, TError, { data: CreateCommentDto }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postCommentCreate>>,
    { data: CreateCommentDto }
  > = props => {
    const { data } = props ?? {};

    return postCommentCreate(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostCommentCreateMutationResult = NonNullable<Awaited<ReturnType<typeof postCommentCreate>>>;
export type PostCommentCreateMutationBody = CreateCommentDto;
export type PostCommentCreateMutationError = HttpValidationProblemDetails | Error;

export const usePostCommentCreate = <TError = HttpValidationProblemDetails | Error, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCommentCreate>>,
    TError,
    { data: CreateCommentDto },
    TContext
  >;
}): UseMutationResult<Awaited<ReturnType<typeof postCommentCreate>>, TError, { data: CreateCommentDto }, TContext> => {
  const mutationOptions = getPostCommentCreateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getConnectionInformation = (params: GetConnectionInformationParams, signal?: AbortSignal) => {
  return fetchInstance<ConnectionInformationDto>({ url: `/connection-information`, method: 'GET', params, signal });
};

export const getGetConnectionInformationQueryKey = (params: GetConnectionInformationParams) => {
  return [`/connection-information`, ...(params ? [params] : [])] as const;
};

export const getGetConnectionInformationQueryOptions = <
  TData = Awaited<ReturnType<typeof getConnectionInformation>>,
  TError = unknown,
>(
  params: GetConnectionInformationParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getConnectionInformation>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetConnectionInformationQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getConnectionInformation>>> = ({ signal }) =>
    getConnectionInformation(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getConnectionInformation>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetConnectionInformationQueryResult = NonNullable<Awaited<ReturnType<typeof getConnectionInformation>>>;
export type GetConnectionInformationQueryError = unknown;

export const useGetConnectionInformation = <
  TData = Awaited<ReturnType<typeof getConnectionInformation>>,
  TError = unknown,
>(
  params: GetConnectionInformationParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getConnectionInformation>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetConnectionInformationQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getContact = (signal?: AbortSignal) => {
  return fetchInstance<ContactDtoListResultDto>({ url: `/contact`, method: 'GET', signal });
};

export const getGetContactQueryKey = () => {
  return [`/contact`] as const;
};

export const getGetContactQueryOptions = <TData = Awaited<ReturnType<typeof getContact>>, TError = unknown>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getContact>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetContactQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getContact>>> = ({ signal }) => getContact(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getContact>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetContactQueryResult = NonNullable<Awaited<ReturnType<typeof getContact>>>;
export type GetContactQueryError = unknown;

export const useGetContact = <TData = Awaited<ReturnType<typeof getContact>>, TError = unknown>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getContact>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetContactQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getContactDetailsId = (id: string, signal?: AbortSignal) => {
  return fetchInstance<ContactDetailsDto>({ url: `/contact/details/${id}`, method: 'GET', signal });
};

export const getGetContactDetailsIdQueryKey = (id: string) => {
  return [`/contact/details/${id}`] as const;
};

export const getGetContactDetailsIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getContactDetailsId>>,
  TError = Error,
>(
  id: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getContactDetailsId>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetContactDetailsIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getContactDetailsId>>> = ({ signal }) =>
    getContactDetailsId(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getContactDetailsId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetContactDetailsIdQueryResult = NonNullable<Awaited<ReturnType<typeof getContactDetailsId>>>;
export type GetContactDetailsIdQueryError = Error;

export const useGetContactDetailsId = <TData = Awaited<ReturnType<typeof getContactDetailsId>>, TError = Error>(
  id: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getContactDetailsId>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetContactDetailsIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putContactDetailsId = (id: string, createUpdateContactDetailsDto: CreateUpdateContactDetailsDto) => {
  return fetchInstance<void>({
    url: `/contact/details/${id}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: createUpdateContactDetailsDto,
  });
};

export const getPutContactDetailsIdMutationOptions = <
  TError = HttpValidationProblemDetails | Error,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putContactDetailsId>>,
    TError,
    { id: string; data: CreateUpdateContactDetailsDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putContactDetailsId>>,
  TError,
  { id: string; data: CreateUpdateContactDetailsDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putContactDetailsId>>,
    { id: string; data: CreateUpdateContactDetailsDto }
  > = props => {
    const { id, data } = props ?? {};

    return putContactDetailsId(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutContactDetailsIdMutationResult = NonNullable<Awaited<ReturnType<typeof putContactDetailsId>>>;
export type PutContactDetailsIdMutationBody = CreateUpdateContactDetailsDto;
export type PutContactDetailsIdMutationError = HttpValidationProblemDetails | Error;

export const usePutContactDetailsId = <TError = HttpValidationProblemDetails | Error, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putContactDetailsId>>,
    TError,
    { id: string; data: CreateUpdateContactDetailsDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof putContactDetailsId>>,
  TError,
  { id: string; data: CreateUpdateContactDetailsDto },
  TContext
> => {
  const mutationOptions = getPutContactDetailsIdMutationOptions(options);

  return useMutation(mutationOptions);
};

export const deleteContactDetailsId = (id: string) => {
  return fetchInstance<void>({ url: `/contact/details/${id}`, method: 'DELETE' });
};

export const getDeleteContactDetailsIdMutationOptions = <TError = Error, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteContactDetailsId>>, TError, { id: string }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof deleteContactDetailsId>>, TError, { id: string }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteContactDetailsId>>, { id: string }> = props => {
    const { id } = props ?? {};

    return deleteContactDetailsId(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteContactDetailsIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteContactDetailsId>>>;

export type DeleteContactDetailsIdMutationError = Error;

export const useDeleteContactDetailsId = <TError = Error, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteContactDetailsId>>, TError, { id: string }, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof deleteContactDetailsId>>, TError, { id: string }, TContext> => {
  const mutationOptions = getDeleteContactDetailsIdMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getContactDetails = (contactFilterListRequestDto: ContactFilterListRequestDto) => {
  return fetchInstance<ContactDetailsDtoListResultDto>({
    url: `/contact/details`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: contactFilterListRequestDto,
  });
};

export const getGetContactDetailsQueryKey = (contactFilterListRequestDto: ContactFilterListRequestDto) => {
  return [`/contact/details`, contactFilterListRequestDto] as const;
};

export const getGetContactDetailsQueryOptions = <
  TData = Awaited<ReturnType<typeof getContactDetails>>,
  TError = HttpValidationProblemDetails,
>(
  contactFilterListRequestDto: ContactFilterListRequestDto,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getContactDetails>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetContactDetailsQueryKey(contactFilterListRequestDto);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getContactDetails>>> = () =>
    getContactDetails(contactFilterListRequestDto);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getContactDetails>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetContactDetailsQueryResult = NonNullable<Awaited<ReturnType<typeof getContactDetails>>>;
export type GetContactDetailsQueryError = HttpValidationProblemDetails;

export const useGetContactDetails = <
  TData = Awaited<ReturnType<typeof getContactDetails>>,
  TError = HttpValidationProblemDetails,
>(
  contactFilterListRequestDto: ContactFilterListRequestDto,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getContactDetails>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetContactDetailsQueryOptions(contactFilterListRequestDto, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putContactDetailsMyInformation = (updateContactInformationDto: UpdateContactInformationDto) => {
  return fetchInstance<void>({
    url: `/contact/details/my-information`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: updateContactInformationDto,
  });
};

export const getPutContactDetailsMyInformationMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putContactDetailsMyInformation>>,
    TError,
    { data: UpdateContactInformationDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putContactDetailsMyInformation>>,
  TError,
  { data: UpdateContactInformationDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putContactDetailsMyInformation>>,
    { data: UpdateContactInformationDto }
  > = props => {
    const { data } = props ?? {};

    return putContactDetailsMyInformation(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutContactDetailsMyInformationMutationResult = NonNullable<
  Awaited<ReturnType<typeof putContactDetailsMyInformation>>
>;
export type PutContactDetailsMyInformationMutationBody = UpdateContactInformationDto;
export type PutContactDetailsMyInformationMutationError = unknown;

export const usePutContactDetailsMyInformation = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putContactDetailsMyInformation>>,
    TError,
    { data: UpdateContactInformationDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof putContactDetailsMyInformation>>,
  TError,
  { data: UpdateContactInformationDto },
  TContext
> => {
  const mutationOptions = getPutContactDetailsMyInformationMutationOptions(options);

  return useMutation(mutationOptions);
};

export const postContactDetailsExport = (contactFilterListRequestDto: ContactFilterListRequestDto) => {
  return fetchInstance<Blob>({
    url: `/contact/details/export`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: contactFilterListRequestDto,
    responseType: 'blob',
  });
};

export const getPostContactDetailsExportMutationOptions = <
  TError = HttpValidationProblemDetails,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postContactDetailsExport>>,
    TError,
    { data: ContactFilterListRequestDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postContactDetailsExport>>,
  TError,
  { data: ContactFilterListRequestDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postContactDetailsExport>>,
    { data: ContactFilterListRequestDto }
  > = props => {
    const { data } = props ?? {};

    return postContactDetailsExport(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostContactDetailsExportMutationResult = NonNullable<Awaited<ReturnType<typeof postContactDetailsExport>>>;
export type PostContactDetailsExportMutationBody = ContactFilterListRequestDto;
export type PostContactDetailsExportMutationError = HttpValidationProblemDetails;

export const usePostContactDetailsExport = <TError = HttpValidationProblemDetails, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postContactDetailsExport>>,
    TError,
    { data: ContactFilterListRequestDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof postContactDetailsExport>>,
  TError,
  { data: ContactFilterListRequestDto },
  TContext
> => {
  const mutationOptions = getPostContactDetailsExportMutationOptions(options);

  return useMutation(mutationOptions);
};

export const postContactDetailsCreate = (createUpdateContactDetailsDto: CreateUpdateContactDetailsDto) => {
  return fetchInstance<CreateResponseDto>({
    url: `/contact/details/create`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createUpdateContactDetailsDto,
  });
};

export const getPostContactDetailsCreateMutationOptions = <
  TError = HttpValidationProblemDetails | Error,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postContactDetailsCreate>>,
    TError,
    { data: CreateUpdateContactDetailsDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postContactDetailsCreate>>,
  TError,
  { data: CreateUpdateContactDetailsDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postContactDetailsCreate>>,
    { data: CreateUpdateContactDetailsDto }
  > = props => {
    const { data } = props ?? {};

    return postContactDetailsCreate(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostContactDetailsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof postContactDetailsCreate>>>;
export type PostContactDetailsCreateMutationBody = CreateUpdateContactDetailsDto;
export type PostContactDetailsCreateMutationError = HttpValidationProblemDetails | Error;

export const usePostContactDetailsCreate = <
  TError = HttpValidationProblemDetails | Error,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postContactDetailsCreate>>,
    TError,
    { data: CreateUpdateContactDetailsDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof postContactDetailsCreate>>,
  TError,
  { data: CreateUpdateContactDetailsDto },
  TContext
> => {
  const mutationOptions = getPostContactDetailsCreateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const postContactDetailsIdTeam = (id: string, addContactToTeamDto: AddContactToTeamDto) => {
  return fetchInstance<void>({
    url: `/contact/details/${id}/team`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: addContactToTeamDto,
  });
};

export const getPostContactDetailsIdTeamMutationOptions = <
  TError = HttpValidationProblemDetails | Error,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postContactDetailsIdTeam>>,
    TError,
    { id: string; data: AddContactToTeamDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postContactDetailsIdTeam>>,
  TError,
  { id: string; data: AddContactToTeamDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postContactDetailsIdTeam>>,
    { id: string; data: AddContactToTeamDto }
  > = props => {
    const { id, data } = props ?? {};

    return postContactDetailsIdTeam(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostContactDetailsIdTeamMutationResult = NonNullable<Awaited<ReturnType<typeof postContactDetailsIdTeam>>>;
export type PostContactDetailsIdTeamMutationBody = AddContactToTeamDto;
export type PostContactDetailsIdTeamMutationError = HttpValidationProblemDetails | Error;

export const usePostContactDetailsIdTeam = <
  TError = HttpValidationProblemDetails | Error,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postContactDetailsIdTeam>>,
    TError,
    { id: string; data: AddContactToTeamDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof postContactDetailsIdTeam>>,
  TError,
  { id: string; data: AddContactToTeamDto },
  TContext
> => {
  const mutationOptions = getPostContactDetailsIdTeamMutationOptions(options);

  return useMutation(mutationOptions);
};

export const deleteContactDetailsContactIdTeamTeamId = (contactId: string, teamId: string) => {
  return fetchInstance<void>({ url: `/contact/details/${contactId}/team/${teamId}`, method: 'DELETE' });
};

export const getDeleteContactDetailsContactIdTeamTeamIdMutationOptions = <
  TError = Error,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteContactDetailsContactIdTeamTeamId>>,
    TError,
    { contactId: string; teamId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteContactDetailsContactIdTeamTeamId>>,
  TError,
  { contactId: string; teamId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteContactDetailsContactIdTeamTeamId>>,
    { contactId: string; teamId: string }
  > = props => {
    const { contactId, teamId } = props ?? {};

    return deleteContactDetailsContactIdTeamTeamId(contactId, teamId);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteContactDetailsContactIdTeamTeamIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteContactDetailsContactIdTeamTeamId>>
>;

export type DeleteContactDetailsContactIdTeamTeamIdMutationError = Error;

export const useDeleteContactDetailsContactIdTeamTeamId = <TError = Error, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteContactDetailsContactIdTeamTeamId>>,
    TError,
    { contactId: string; teamId: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteContactDetailsContactIdTeamTeamId>>,
  TError,
  { contactId: string; teamId: string },
  TContext
> => {
  const mutationOptions = getDeleteContactDetailsContactIdTeamTeamIdMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getCprContactFilters = (signal?: AbortSignal) => {
  return fetchInstance<CprFiltersDto>({ url: `/cpr-contact/filters`, method: 'GET', signal });
};

export const getGetCprContactFiltersQueryKey = () => {
  return [`/cpr-contact/filters`] as const;
};

export const getGetCprContactFiltersQueryOptions = <
  TData = Awaited<ReturnType<typeof getCprContactFilters>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getCprContactFilters>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCprContactFiltersQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCprContactFilters>>> = ({ signal }) =>
    getCprContactFilters(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCprContactFilters>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCprContactFiltersQueryResult = NonNullable<Awaited<ReturnType<typeof getCprContactFilters>>>;
export type GetCprContactFiltersQueryError = unknown;

export const useGetCprContactFilters = <
  TData = Awaited<ReturnType<typeof getCprContactFilters>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getCprContactFilters>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCprContactFiltersQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCprContact = (cprContactFilterListRequestDto: CprContactFilterListRequestDto) => {
  return fetchInstance<CprContactDtoListResultDto>({
    url: `/cpr-contact`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: cprContactFilterListRequestDto,
  });
};

export const getGetCprContactQueryKey = (cprContactFilterListRequestDto: CprContactFilterListRequestDto) => {
  return [`/cpr-contact`, cprContactFilterListRequestDto] as const;
};

export const getGetCprContactQueryOptions = <
  TData = Awaited<ReturnType<typeof getCprContact>>,
  TError = HttpValidationProblemDetails,
>(
  cprContactFilterListRequestDto: CprContactFilterListRequestDto,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getCprContact>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCprContactQueryKey(cprContactFilterListRequestDto);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCprContact>>> = () =>
    getCprContact(cprContactFilterListRequestDto);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCprContact>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCprContactQueryResult = NonNullable<Awaited<ReturnType<typeof getCprContact>>>;
export type GetCprContactQueryError = HttpValidationProblemDetails;

export const useGetCprContact = <
  TData = Awaited<ReturnType<typeof getCprContact>>,
  TError = HttpValidationProblemDetails,
>(
  cprContactFilterListRequestDto: CprContactFilterListRequestDto,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getCprContact>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCprContactQueryOptions(cprContactFilterListRequestDto, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postCprContactExport = (cprContactFilterListRequestDto: CprContactFilterListRequestDto) => {
  return fetchInstance<Blob>({
    url: `/cpr-contact/export`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: cprContactFilterListRequestDto,
    responseType: 'blob',
  });
};

export const getPostCprContactExportMutationOptions = <
  TError = HttpValidationProblemDetails,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCprContactExport>>,
    TError,
    { data: CprContactFilterListRequestDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postCprContactExport>>,
  TError,
  { data: CprContactFilterListRequestDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postCprContactExport>>,
    { data: CprContactFilterListRequestDto }
  > = props => {
    const { data } = props ?? {};

    return postCprContactExport(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostCprContactExportMutationResult = NonNullable<Awaited<ReturnType<typeof postCprContactExport>>>;
export type PostCprContactExportMutationBody = CprContactFilterListRequestDto;
export type PostCprContactExportMutationError = HttpValidationProblemDetails;

export const usePostCprContactExport = <TError = HttpValidationProblemDetails, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCprContactExport>>,
    TError,
    { data: CprContactFilterListRequestDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof postCprContactExport>>,
  TError,
  { data: CprContactFilterListRequestDto },
  TContext
> => {
  const mutationOptions = getPostCprContactExportMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getCprManagementContact = (
  cprManagementContactFilterListRequestDto: CprManagementContactFilterListRequestDto
) => {
  return fetchInstance<CprManagementContactDtoListResultDto>({
    url: `/cpr-management-contact`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: cprManagementContactFilterListRequestDto,
  });
};

export const getGetCprManagementContactQueryKey = (
  cprManagementContactFilterListRequestDto: CprManagementContactFilterListRequestDto
) => {
  return [`/cpr-management-contact`, cprManagementContactFilterListRequestDto] as const;
};

export const getGetCprManagementContactQueryOptions = <
  TData = Awaited<ReturnType<typeof getCprManagementContact>>,
  TError = HttpValidationProblemDetails,
>(
  cprManagementContactFilterListRequestDto: CprManagementContactFilterListRequestDto,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getCprManagementContact>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCprManagementContactQueryKey(cprManagementContactFilterListRequestDto);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCprManagementContact>>> = () =>
    getCprManagementContact(cprManagementContactFilterListRequestDto);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCprManagementContact>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCprManagementContactQueryResult = NonNullable<Awaited<ReturnType<typeof getCprManagementContact>>>;
export type GetCprManagementContactQueryError = HttpValidationProblemDetails;

export const useGetCprManagementContact = <
  TData = Awaited<ReturnType<typeof getCprManagementContact>>,
  TError = HttpValidationProblemDetails,
>(
  cprManagementContactFilterListRequestDto: CprManagementContactFilterListRequestDto,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getCprManagementContact>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCprManagementContactQueryOptions(cprManagementContactFilterListRequestDto, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const deleteCprManagementContactId = (id: string) => {
  return fetchInstance<void>({ url: `/cpr-management-contact/${id}`, method: 'DELETE' });
};

export const getDeleteCprManagementContactIdMutationOptions = <TError = Error, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCprManagementContactId>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<Awaited<ReturnType<typeof deleteCprManagementContactId>>, TError, { id: string }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteCprManagementContactId>>,
    { id: string }
  > = props => {
    const { id } = props ?? {};

    return deleteCprManagementContactId(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteCprManagementContactIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteCprManagementContactId>>
>;

export type DeleteCprManagementContactIdMutationError = Error;

export const useDeleteCprManagementContactId = <TError = Error, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCprManagementContactId>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationResult<Awaited<ReturnType<typeof deleteCprManagementContactId>>, TError, { id: string }, TContext> => {
  const mutationOptions = getDeleteCprManagementContactIdMutationOptions(options);

  return useMutation(mutationOptions);
};

export const postCprContact = (createCprContactDto: CreateCprContactDto) => {
  return fetchInstance<CreateResponseDto>({
    url: `/cpr-management-contact/create`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createCprContactDto,
  });
};

export const getPostCprContactMutationOptions = <
  TError = HttpValidationProblemDetails | Error,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCprContact>>,
    TError,
    { data: CreateCprContactDto },
    TContext
  >;
}): UseMutationOptions<Awaited<ReturnType<typeof postCprContact>>, TError, { data: CreateCprContactDto }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postCprContact>>,
    { data: CreateCprContactDto }
  > = props => {
    const { data } = props ?? {};

    return postCprContact(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostCprContactMutationResult = NonNullable<Awaited<ReturnType<typeof postCprContact>>>;
export type PostCprContactMutationBody = CreateCprContactDto;
export type PostCprContactMutationError = HttpValidationProblemDetails | Error;

export const usePostCprContact = <TError = HttpValidationProblemDetails | Error, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCprContact>>,
    TError,
    { data: CreateCprContactDto },
    TContext
  >;
}): UseMutationResult<Awaited<ReturnType<typeof postCprContact>>, TError, { data: CreateCprContactDto }, TContext> => {
  const mutationOptions = getPostCprContactMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getManagementContact = (managementContactFilterListRequestDto: ManagementContactFilterListRequestDto) => {
  return fetchInstance<ContactDtoListResultDto>({
    url: `/cpr-management-contact/contact`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: managementContactFilterListRequestDto,
  });
};

export const getGetManagementContactQueryKey = (
  managementContactFilterListRequestDto: ManagementContactFilterListRequestDto
) => {
  return [`/cpr-management-contact/contact`, managementContactFilterListRequestDto] as const;
};

export const getGetManagementContactQueryOptions = <
  TData = Awaited<ReturnType<typeof getManagementContact>>,
  TError = HttpValidationProblemDetails,
>(
  managementContactFilterListRequestDto: ManagementContactFilterListRequestDto,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getManagementContact>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetManagementContactQueryKey(managementContactFilterListRequestDto);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getManagementContact>>> = () =>
    getManagementContact(managementContactFilterListRequestDto);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getManagementContact>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetManagementContactQueryResult = NonNullable<Awaited<ReturnType<typeof getManagementContact>>>;
export type GetManagementContactQueryError = HttpValidationProblemDetails;

export const useGetManagementContact = <
  TData = Awaited<ReturnType<typeof getManagementContact>>,
  TError = HttpValidationProblemDetails,
>(
  managementContactFilterListRequestDto: ManagementContactFilterListRequestDto,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getManagementContact>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetManagementContactQueryOptions(managementContactFilterListRequestDto, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postCprManagementContactContactCreate = (createUpdateContactDto: CreateUpdateContactDto) => {
  return fetchInstance<CreateResponseDto>({
    url: `/cpr-management-contact/contact/create`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createUpdateContactDto,
  });
};

export const getPostCprManagementContactContactCreateMutationOptions = <
  TError = HttpValidationProblemDetails,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCprManagementContactContactCreate>>,
    TError,
    { data: CreateUpdateContactDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postCprManagementContactContactCreate>>,
  TError,
  { data: CreateUpdateContactDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postCprManagementContactContactCreate>>,
    { data: CreateUpdateContactDto }
  > = props => {
    const { data } = props ?? {};

    return postCprManagementContactContactCreate(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostCprManagementContactContactCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof postCprManagementContactContactCreate>>
>;
export type PostCprManagementContactContactCreateMutationBody = CreateUpdateContactDto;
export type PostCprManagementContactContactCreateMutationError = HttpValidationProblemDetails;

export const usePostCprManagementContactContactCreate = <
  TError = HttpValidationProblemDetails,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCprManagementContactContactCreate>>,
    TError,
    { data: CreateUpdateContactDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof postCprManagementContactContactCreate>>,
  TError,
  { data: CreateUpdateContactDto },
  TContext
> => {
  const mutationOptions = getPostCprManagementContactContactCreateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const putCprManagementContactContactId = (id: string, createUpdateContactDto: CreateUpdateContactDto) => {
  return fetchInstance<void>({
    url: `/cpr-management-contact/contact/${id}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: createUpdateContactDto,
  });
};

export const getPutCprManagementContactContactIdMutationOptions = <
  TError = HttpValidationProblemDetails | Error,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putCprManagementContactContactId>>,
    TError,
    { id: string; data: CreateUpdateContactDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putCprManagementContactContactId>>,
  TError,
  { id: string; data: CreateUpdateContactDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putCprManagementContactContactId>>,
    { id: string; data: CreateUpdateContactDto }
  > = props => {
    const { id, data } = props ?? {};

    return putCprManagementContactContactId(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutCprManagementContactContactIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof putCprManagementContactContactId>>
>;
export type PutCprManagementContactContactIdMutationBody = CreateUpdateContactDto;
export type PutCprManagementContactContactIdMutationError = HttpValidationProblemDetails | Error;

export const usePutCprManagementContactContactId = <
  TError = HttpValidationProblemDetails | Error,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putCprManagementContactContactId>>,
    TError,
    { id: string; data: CreateUpdateContactDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof putCprManagementContactContactId>>,
  TError,
  { id: string; data: CreateUpdateContactDto },
  TContext
> => {
  const mutationOptions = getPutCprManagementContactContactIdMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getCprOrganisations = (cprOrganisationFilterListRequestDto: CprOrganisationFilterListRequestDto) => {
  return fetchInstance<CprOrganisationDtoListResultDto>({
    url: `/cpr-organisation`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: cprOrganisationFilterListRequestDto,
  });
};

export const getGetCprOrganisationsQueryKey = (
  cprOrganisationFilterListRequestDto: CprOrganisationFilterListRequestDto
) => {
  return [`/cpr-organisation`, cprOrganisationFilterListRequestDto] as const;
};

export const getGetCprOrganisationsQueryOptions = <
  TData = Awaited<ReturnType<typeof getCprOrganisations>>,
  TError = HttpValidationProblemDetails,
>(
  cprOrganisationFilterListRequestDto: CprOrganisationFilterListRequestDto,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getCprOrganisations>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCprOrganisationsQueryKey(cprOrganisationFilterListRequestDto);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCprOrganisations>>> = () =>
    getCprOrganisations(cprOrganisationFilterListRequestDto);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCprOrganisations>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCprOrganisationsQueryResult = NonNullable<Awaited<ReturnType<typeof getCprOrganisations>>>;
export type GetCprOrganisationsQueryError = HttpValidationProblemDetails;

export const useGetCprOrganisations = <
  TData = Awaited<ReturnType<typeof getCprOrganisations>>,
  TError = HttpValidationProblemDetails,
>(
  cprOrganisationFilterListRequestDto: CprOrganisationFilterListRequestDto,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getCprOrganisations>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCprOrganisationsQueryOptions(cprOrganisationFilterListRequestDto, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postCprOrganisationExport = (cprOrganisationFilterListRequestDto: CprOrganisationFilterListRequestDto) => {
  return fetchInstance<Blob>({
    url: `/cpr-organisation/export`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: cprOrganisationFilterListRequestDto,
    responseType: 'blob',
  });
};

export const getPostCprOrganisationExportMutationOptions = <
  TError = HttpValidationProblemDetails,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCprOrganisationExport>>,
    TError,
    { data: CprOrganisationFilterListRequestDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postCprOrganisationExport>>,
  TError,
  { data: CprOrganisationFilterListRequestDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postCprOrganisationExport>>,
    { data: CprOrganisationFilterListRequestDto }
  > = props => {
    const { data } = props ?? {};

    return postCprOrganisationExport(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostCprOrganisationExportMutationResult = NonNullable<
  Awaited<ReturnType<typeof postCprOrganisationExport>>
>;
export type PostCprOrganisationExportMutationBody = CprOrganisationFilterListRequestDto;
export type PostCprOrganisationExportMutationError = HttpValidationProblemDetails;

export const usePostCprOrganisationExport = <TError = HttpValidationProblemDetails, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCprOrganisationExport>>,
    TError,
    { data: CprOrganisationFilterListRequestDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof postCprOrganisationExport>>,
  TError,
  { data: CprOrganisationFilterListRequestDto },
  TContext
> => {
  const mutationOptions = getPostCprOrganisationExportMutationOptions(options);

  return useMutation(mutationOptions);
};

export const postDataRequest = (createDataRequestDto: CreateDataRequestDto) => {
  const formData = new FormData();
  formData.append('dataRelatedToDataset', createDataRequestDto.dataRelatedToDataset);
  if (
    createDataRequestDto.dataRelatedToDatasetNote !== undefined &&
    createDataRequestDto.dataRelatedToDatasetNote !== null
  ) {
    formData.append('dataRelatedToDatasetNote', createDataRequestDto.dataRelatedToDatasetNote);
  }
  if (createDataRequestDto.reason !== undefined) {
    formData.append('reason', createDataRequestDto.reason);
  }
  if (createDataRequestDto.reasonNote !== undefined && createDataRequestDto.reasonNote !== null) {
    formData.append('reasonNote', createDataRequestDto.reasonNote);
  }
  if (createDataRequestDto.dataRequestTo !== undefined) {
    createDataRequestDto.dataRequestTo.forEach(value => formData.append('dataRequestTo', value));
  }
  if (createDataRequestDto.dataTypes !== undefined) {
    createDataRequestDto.dataTypes.forEach(value => formData.append('dataTypes', value));
  }
  if (createDataRequestDto.dataTypeNote !== undefined && createDataRequestDto.dataTypeNote !== null) {
    formData.append('dataTypeNote', createDataRequestDto.dataTypeNote);
  }
  if (createDataRequestDto.energyTypes !== undefined) {
    createDataRequestDto.energyTypes.forEach(value => formData.append('energyTypes', value));
  }
  if (createDataRequestDto.assetTypes !== undefined) {
    createDataRequestDto.assetTypes.forEach(value => formData.append('assetTypes', value));
  }
  if (createDataRequestDto.assetTypeNote !== undefined && createDataRequestDto.assetTypeNote !== null) {
    formData.append('assetTypeNote', createDataRequestDto.assetTypeNote);
  }
  if (createDataRequestDto.energyTypesConsumption !== undefined) {
    createDataRequestDto.energyTypesConsumption.forEach(value => formData.append('energyTypesConsumption', value));
  }
  if (createDataRequestDto.consumptionTypes !== undefined) {
    createDataRequestDto.consumptionTypes.forEach(value => formData.append('consumptionTypes', value));
  }
  if (createDataRequestDto.consumptionTypeNote !== undefined && createDataRequestDto.consumptionTypeNote !== null) {
    formData.append('consumptionTypeNote', createDataRequestDto.consumptionTypeNote);
  }
  if (createDataRequestDto.level !== undefined) {
    formData.append('level', createDataRequestDto.level);
  }
  if (createDataRequestDto.levelNote !== undefined && createDataRequestDto.levelNote !== null) {
    formData.append('levelNote', createDataRequestDto.levelNote);
  }
  if (createDataRequestDto.addressLevel !== undefined) {
    formData.append('addressLevel', createDataRequestDto.addressLevel);
  }
  if (createDataRequestDto.zipCodeLevel !== undefined) {
    formData.append('zipCodeLevel', createDataRequestDto.zipCodeLevel);
  }
  if (createDataRequestDto.zipCodeLevelNote !== undefined && createDataRequestDto.zipCodeLevelNote !== null) {
    formData.append('zipCodeLevelNote', createDataRequestDto.zipCodeLevelNote);
  }
  if (createDataRequestDto.partitionLevel !== undefined) {
    formData.append('partitionLevel', createDataRequestDto.partitionLevel);
  }
  if (createDataRequestDto.partitionLevelNote !== undefined && createDataRequestDto.partitionLevelNote !== null) {
    formData.append('partitionLevelNote', createDataRequestDto.partitionLevelNote);
  }
  if (createDataRequestDto.partitionLevelZipCode !== undefined) {
    formData.append('partitionLevelZipCode', createDataRequestDto.partitionLevelZipCode);
  }
  if (
    createDataRequestDto.partitionLevelZipCodeNote !== undefined &&
    createDataRequestDto.partitionLevelZipCodeNote !== null
  ) {
    formData.append('partitionLevelZipCodeNote', createDataRequestDto.partitionLevelZipCodeNote);
  }
  if (createDataRequestDto.fileFormats !== undefined) {
    createDataRequestDto.fileFormats.forEach(value => formData.append('fileFormats', value));
  }
  if (createDataRequestDto.fileFormatNote !== undefined && createDataRequestDto.fileFormatNote !== null) {
    formData.append('fileFormatNote', createDataRequestDto.fileFormatNote);
  }
  if (createDataRequestDto.periods !== undefined) {
    createDataRequestDto.periods.forEach(value => formData.append('periods', value));
  }
  if (createDataRequestDto.periodNote !== undefined && createDataRequestDto.periodNote !== null) {
    formData.append('periodNote', createDataRequestDto.periodNote);
  }
  if (createDataRequestDto.earlierSameData !== undefined) {
    formData.append('earlierSameData', createDataRequestDto.earlierSameData);
  }
  if (createDataRequestDto.earlierSameDataNote !== undefined && createDataRequestDto.earlierSameDataNote !== null) {
    formData.append('earlierSameDataNote', createDataRequestDto.earlierSameDataNote);
  }
  if (createDataRequestDto.canObtainOtherwise !== undefined) {
    formData.append('canObtainOtherwise', createDataRequestDto.canObtainOtherwise);
  }
  if (
    createDataRequestDto.canObtainOtherwiseNote !== undefined &&
    createDataRequestDto.canObtainOtherwiseNote !== null
  ) {
    formData.append('canObtainOtherwiseNote', createDataRequestDto.canObtainOtherwiseNote);
  }
  if (createDataRequestDto.legalObligation !== undefined) {
    formData.append('legalObligation', createDataRequestDto.legalObligation);
  }
  if (createDataRequestDto.legalObligationReason !== undefined) {
    formData.append('legalObligationReason', createDataRequestDto.legalObligationReason);
  }
  if (
    createDataRequestDto.legalObligationReasonNote !== undefined &&
    createDataRequestDto.legalObligationReasonNote !== null
  ) {
    formData.append('legalObligationReasonNote', createDataRequestDto.legalObligationReasonNote);
  }
  if (createDataRequestDto.informationIsPersonalData !== undefined) {
    formData.append('informationIsPersonalData', createDataRequestDto.informationIsPersonalData);
  }
  if (createDataRequestDto.legalBasisPersonalData !== undefined) {
    formData.append('legalBasisPersonalData', createDataRequestDto.legalBasisPersonalData);
  }
  if (createDataRequestDto.legalBasisPersonalDataReason !== undefined) {
    formData.append('legalBasisPersonalDataReason', createDataRequestDto.legalBasisPersonalDataReason);
  }
  if (
    createDataRequestDto.legalBasisPersonalDataReasonNote !== undefined &&
    createDataRequestDto.legalBasisPersonalDataReasonNote !== null
  ) {
    formData.append('legalBasisPersonalDataReasonNote', createDataRequestDto.legalBasisPersonalDataReasonNote);
  }
  if (createDataRequestDto.levelFile !== undefined && createDataRequestDto.levelFile !== null) {
    formData.append('levelFile', createDataRequestDto.levelFile);
  }
  if (createDataRequestDto.partitionFile !== undefined && createDataRequestDto.partitionFile !== null) {
    formData.append('partitionFile', createDataRequestDto.partitionFile);
  }
  if (createDataRequestDto.customerMandateFile !== undefined && createDataRequestDto.customerMandateFile !== null) {
    formData.append('customerMandateFile', createDataRequestDto.customerMandateFile);
  }

  return fetchInstance<CreateResponseDto>({
    url: `/dataRequest/create`,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
  });
};

export const getPostDataRequestMutationOptions = <TError = HttpValidationProblemDetails, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postDataRequest>>,
    TError,
    { data: CreateDataRequestDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postDataRequest>>,
  TError,
  { data: CreateDataRequestDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postDataRequest>>,
    { data: CreateDataRequestDto }
  > = props => {
    const { data } = props ?? {};

    return postDataRequest(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostDataRequestMutationResult = NonNullable<Awaited<ReturnType<typeof postDataRequest>>>;
export type PostDataRequestMutationBody = CreateDataRequestDto;
export type PostDataRequestMutationError = HttpValidationProblemDetails;

export const usePostDataRequest = <TError = HttpValidationProblemDetails, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postDataRequest>>,
    TError,
    { data: CreateDataRequestDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof postDataRequest>>,
  TError,
  { data: CreateDataRequestDto },
  TContext
> => {
  const mutationOptions = getPostDataRequestMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getGridOperator = (signal?: AbortSignal) => {
  return fetchInstance<GridOperatorDtoListResultDto>({ url: `/grid-operator`, method: 'GET', signal });
};

export const getGetGridOperatorQueryKey = () => {
  return [`/grid-operator`] as const;
};

export const getGetGridOperatorQueryOptions = <
  TData = Awaited<ReturnType<typeof getGridOperator>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getGridOperator>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetGridOperatorQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getGridOperator>>> = ({ signal }) => getGridOperator(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getGridOperator>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetGridOperatorQueryResult = NonNullable<Awaited<ReturnType<typeof getGridOperator>>>;
export type GetGridOperatorQueryError = unknown;

export const useGetGridOperator = <TData = Awaited<ReturnType<typeof getGridOperator>>, TError = unknown>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getGridOperator>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetGridOperatorQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getKnowledgeArticle = (knowledgeArticleFilterListRequestDto: KnowledgeArticleFilterListRequestDto) => {
  return fetchInstance<KnowledgeArticleListDtoListResultDto>({
    url: `/knowledge-article`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: knowledgeArticleFilterListRequestDto,
  });
};

export const getGetKnowledgeArticleQueryKey = (
  knowledgeArticleFilterListRequestDto: KnowledgeArticleFilterListRequestDto
) => {
  return [`/knowledge-article`, knowledgeArticleFilterListRequestDto] as const;
};

export const getGetKnowledgeArticleQueryOptions = <
  TData = Awaited<ReturnType<typeof getKnowledgeArticle>>,
  TError = HttpValidationProblemDetails,
>(
  knowledgeArticleFilterListRequestDto: KnowledgeArticleFilterListRequestDto,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getKnowledgeArticle>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetKnowledgeArticleQueryKey(knowledgeArticleFilterListRequestDto);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getKnowledgeArticle>>> = () =>
    getKnowledgeArticle(knowledgeArticleFilterListRequestDto);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getKnowledgeArticle>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetKnowledgeArticleQueryResult = NonNullable<Awaited<ReturnType<typeof getKnowledgeArticle>>>;
export type GetKnowledgeArticleQueryError = HttpValidationProblemDetails;

export const useGetKnowledgeArticle = <
  TData = Awaited<ReturnType<typeof getKnowledgeArticle>>,
  TError = HttpValidationProblemDetails,
>(
  knowledgeArticleFilterListRequestDto: KnowledgeArticleFilterListRequestDto,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getKnowledgeArticle>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetKnowledgeArticleQueryOptions(knowledgeArticleFilterListRequestDto, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getKnowledgeArticleId = (id: string, signal?: AbortSignal) => {
  return fetchInstance<KnowledgeArticleDetailDto>({ url: `/knowledge-article/${id}`, method: 'GET', signal });
};

export const getGetKnowledgeArticleIdQueryKey = (id: string) => {
  return [`/knowledge-article/${id}`] as const;
};

export const getGetKnowledgeArticleIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getKnowledgeArticleId>>,
  TError = Error,
>(
  id: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getKnowledgeArticleId>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetKnowledgeArticleIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getKnowledgeArticleId>>> = ({ signal }) =>
    getKnowledgeArticleId(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getKnowledgeArticleId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetKnowledgeArticleIdQueryResult = NonNullable<Awaited<ReturnType<typeof getKnowledgeArticleId>>>;
export type GetKnowledgeArticleIdQueryError = Error;

export const useGetKnowledgeArticleId = <TData = Awaited<ReturnType<typeof getKnowledgeArticleId>>, TError = Error>(
  id: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getKnowledgeArticleId>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetKnowledgeArticleIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getKnowledgeArticleCategories = (signal?: AbortSignal) => {
  return fetchInstance<MainCategoryDtoListResultDto>({ url: `/knowledge-article/categories`, method: 'GET', signal });
};

export const getGetKnowledgeArticleCategoriesQueryKey = () => {
  return [`/knowledge-article/categories`] as const;
};

export const getGetKnowledgeArticleCategoriesQueryOptions = <
  TData = Awaited<ReturnType<typeof getKnowledgeArticleCategories>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getKnowledgeArticleCategories>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetKnowledgeArticleCategoriesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getKnowledgeArticleCategories>>> = ({ signal }) =>
    getKnowledgeArticleCategories(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getKnowledgeArticleCategories>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetKnowledgeArticleCategoriesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getKnowledgeArticleCategories>>
>;
export type GetKnowledgeArticleCategoriesQueryError = unknown;

export const useGetKnowledgeArticleCategories = <
  TData = Awaited<ReturnType<typeof getKnowledgeArticleCategories>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getKnowledgeArticleCategories>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetKnowledgeArticleCategoriesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getKnowledgeArticleImageId = (id: string, signal?: AbortSignal) => {
  return fetchInstance<Blob>({ url: `/knowledge-article/image/${id}`, method: 'GET', responseType: 'blob', signal });
};

export const getGetKnowledgeArticleImageIdQueryKey = (id: string) => {
  return [`/knowledge-article/image/${id}`] as const;
};

export const getGetKnowledgeArticleImageIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getKnowledgeArticleImageId>>,
  TError = Error,
>(
  id: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getKnowledgeArticleImageId>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetKnowledgeArticleImageIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getKnowledgeArticleImageId>>> = ({ signal }) =>
    getKnowledgeArticleImageId(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getKnowledgeArticleImageId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetKnowledgeArticleImageIdQueryResult = NonNullable<Awaited<ReturnType<typeof getKnowledgeArticleImageId>>>;
export type GetKnowledgeArticleImageIdQueryError = Error;

export const useGetKnowledgeArticleImageId = <
  TData = Awaited<ReturnType<typeof getKnowledgeArticleImageId>>,
  TError = Error,
>(
  id: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getKnowledgeArticleImageId>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetKnowledgeArticleImageIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getMainCategory = (signal?: AbortSignal) => {
  return fetchInstance<MainCategoryDtoListResultDto>({ url: `/main-category`, method: 'GET', signal });
};

export const getGetMainCategoryQueryKey = () => {
  return [`/main-category`] as const;
};

export const getGetMainCategoryQueryOptions = <
  TData = Awaited<ReturnType<typeof getMainCategory>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getMainCategory>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMainCategoryQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMainCategory>>> = ({ signal }) => getMainCategory(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getMainCategory>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetMainCategoryQueryResult = NonNullable<Awaited<ReturnType<typeof getMainCategory>>>;
export type GetMainCategoryQueryError = unknown;

export const useGetMainCategory = <TData = Awaited<ReturnType<typeof getMainCategory>>, TError = unknown>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getMainCategory>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMainCategoryQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getNotification = (signal?: AbortSignal) => {
  return fetchInstance<NotificationDto>({ url: `/notification`, method: 'GET', signal });
};

export const getGetNotificationQueryKey = () => {
  return [`/notification`] as const;
};

export const getGetNotificationQueryOptions = <
  TData = Awaited<ReturnType<typeof getNotification>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getNotification>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetNotificationQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getNotification>>> = ({ signal }) => getNotification(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getNotification>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetNotificationQueryResult = NonNullable<Awaited<ReturnType<typeof getNotification>>>;
export type GetNotificationQueryError = unknown;

export const useGetNotification = <TData = Awaited<ReturnType<typeof getNotification>>, TError = unknown>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getNotification>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetNotificationQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putNotification = (updateNotificationDto: UpdateNotificationDto) => {
  return fetchInstance<void>({
    url: `/notification`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: updateNotificationDto,
  });
};

export const getPutNotificationMutationOptions = <TError = HttpValidationProblemDetails, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putNotification>>,
    TError,
    { data: UpdateNotificationDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putNotification>>,
  TError,
  { data: UpdateNotificationDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putNotification>>,
    { data: UpdateNotificationDto }
  > = props => {
    const { data } = props ?? {};

    return putNotification(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutNotificationMutationResult = NonNullable<Awaited<ReturnType<typeof putNotification>>>;
export type PutNotificationMutationBody = UpdateNotificationDto;
export type PutNotificationMutationError = HttpValidationProblemDetails;

export const usePutNotification = <TError = HttpValidationProblemDetails, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putNotification>>,
    TError,
    { data: UpdateNotificationDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof putNotification>>,
  TError,
  { data: UpdateNotificationDto },
  TContext
> => {
  const mutationOptions = getPutNotificationMutationOptions(options);

  return useMutation(mutationOptions);
};

export const deleteNotificationSubCategoryId = (id: string) => {
  return fetchInstance<void>({ url: `/notification/sub-category/${id}`, method: 'DELETE' });
};

export const getDeleteNotificationSubCategoryIdMutationOptions = <TError = Error, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteNotificationSubCategoryId>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteNotificationSubCategoryId>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteNotificationSubCategoryId>>,
    { id: string }
  > = props => {
    const { id } = props ?? {};

    return deleteNotificationSubCategoryId(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteNotificationSubCategoryIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteNotificationSubCategoryId>>
>;

export type DeleteNotificationSubCategoryIdMutationError = Error;

export const useDeleteNotificationSubCategoryId = <TError = Error, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteNotificationSubCategoryId>>,
    TError,
    { id: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteNotificationSubCategoryId>>,
  TError,
  { id: string },
  TContext
> => {
  const mutationOptions = getDeleteNotificationSubCategoryIdMutationOptions(options);

  return useMutation(mutationOptions);
};

export const postNotificationSubCategory = (createSubcategoryNotificationDto: CreateSubcategoryNotificationDto) => {
  return fetchInstance<void>({
    url: `/notification/sub-category`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createSubcategoryNotificationDto,
  });
};

export const getPostNotificationSubCategoryMutationOptions = <
  TError = HttpValidationProblemDetails | Error,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postNotificationSubCategory>>,
    TError,
    { data: CreateSubcategoryNotificationDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postNotificationSubCategory>>,
  TError,
  { data: CreateSubcategoryNotificationDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postNotificationSubCategory>>,
    { data: CreateSubcategoryNotificationDto }
  > = props => {
    const { data } = props ?? {};

    return postNotificationSubCategory(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostNotificationSubCategoryMutationResult = NonNullable<
  Awaited<ReturnType<typeof postNotificationSubCategory>>
>;
export type PostNotificationSubCategoryMutationBody = CreateSubcategoryNotificationDto;
export type PostNotificationSubCategoryMutationError = HttpValidationProblemDetails | Error;

export const usePostNotificationSubCategory = <
  TError = HttpValidationProblemDetails | Error,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postNotificationSubCategory>>,
    TError,
    { data: CreateSubcategoryNotificationDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof postNotificationSubCategory>>,
  TError,
  { data: CreateSubcategoryNotificationDto },
  TContext
> => {
  const mutationOptions = getPostNotificationSubCategoryMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getRejectionCategory = (signal?: AbortSignal) => {
  return fetchInstance<RejectionCategoryDtoListResultDto>({ url: `/rejection-category`, method: 'GET', signal });
};

export const getGetRejectionCategoryQueryKey = () => {
  return [`/rejection-category`] as const;
};

export const getGetRejectionCategoryQueryOptions = <
  TData = Awaited<ReturnType<typeof getRejectionCategory>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getRejectionCategory>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetRejectionCategoryQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRejectionCategory>>> = ({ signal }) =>
    getRejectionCategory(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getRejectionCategory>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetRejectionCategoryQueryResult = NonNullable<Awaited<ReturnType<typeof getRejectionCategory>>>;
export type GetRejectionCategoryQueryError = unknown;

export const useGetRejectionCategory = <
  TData = Awaited<ReturnType<typeof getRejectionCategory>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getRejectionCategory>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetRejectionCategoryQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postSurveyCreate = (surveyResponseDto: SurveyResponseDto) => {
  return fetchInstance<ExecuteMultipleResponseDto>({
    url: `/survey/create`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: surveyResponseDto,
  });
};

export const getPostSurveyCreateMutationOptions = <
  TError = HttpValidationProblemDetails,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSurveyCreate>>,
    TError,
    { data: SurveyResponseDto },
    TContext
  >;
}): UseMutationOptions<Awaited<ReturnType<typeof postSurveyCreate>>, TError, { data: SurveyResponseDto }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postSurveyCreate>>,
    { data: SurveyResponseDto }
  > = props => {
    const { data } = props ?? {};

    return postSurveyCreate(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostSurveyCreateMutationResult = NonNullable<Awaited<ReturnType<typeof postSurveyCreate>>>;
export type PostSurveyCreateMutationBody = SurveyResponseDto;
export type PostSurveyCreateMutationError = HttpValidationProblemDetails;

export const usePostSurveyCreate = <TError = HttpValidationProblemDetails, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSurveyCreate>>,
    TError,
    { data: SurveyResponseDto },
    TContext
  >;
}): UseMutationResult<Awaited<ReturnType<typeof postSurveyCreate>>, TError, { data: SurveyResponseDto }, TContext> => {
  const mutationOptions = getPostSurveyCreateMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getSurveyDialogue = (surveyDialogueFilterListRequestDto: SurveyDialogueFilterListRequestDto) => {
  return fetchInstance<SurveyDialogueDtoListResultDto>({
    url: `/survey`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: surveyDialogueFilterListRequestDto,
  });
};

export const getGetSurveyDialogueQueryKey = (
  surveyDialogueFilterListRequestDto: SurveyDialogueFilterListRequestDto
) => {
  return [`/survey`, surveyDialogueFilterListRequestDto] as const;
};

export const getGetSurveyDialogueQueryOptions = <
  TData = Awaited<ReturnType<typeof getSurveyDialogue>>,
  TError = HttpValidationProblemDetails,
>(
  surveyDialogueFilterListRequestDto: SurveyDialogueFilterListRequestDto,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getSurveyDialogue>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSurveyDialogueQueryKey(surveyDialogueFilterListRequestDto);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSurveyDialogue>>> = () =>
    getSurveyDialogue(surveyDialogueFilterListRequestDto);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getSurveyDialogue>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetSurveyDialogueQueryResult = NonNullable<Awaited<ReturnType<typeof getSurveyDialogue>>>;
export type GetSurveyDialogueQueryError = HttpValidationProblemDetails;

export const useGetSurveyDialogue = <
  TData = Awaited<ReturnType<typeof getSurveyDialogue>>,
  TError = HttpValidationProblemDetails,
>(
  surveyDialogueFilterListRequestDto: SurveyDialogueFilterListRequestDto,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getSurveyDialogue>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSurveyDialogueQueryOptions(surveyDialogueFilterListRequestDto, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getSurveyId = (id: string, signal?: AbortSignal) => {
  return fetchInstance<SurveyDialogueDto>({ url: `/survey/${id}`, method: 'GET', signal });
};

export const getGetSurveyIdQueryKey = (id: string) => {
  return [`/survey/${id}`] as const;
};

export const getGetSurveyIdQueryOptions = <TData = Awaited<ReturnType<typeof getSurveyId>>, TError = Error>(
  id: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getSurveyId>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSurveyIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSurveyId>>> = ({ signal }) => getSurveyId(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getSurveyId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetSurveyIdQueryResult = NonNullable<Awaited<ReturnType<typeof getSurveyId>>>;
export type GetSurveyIdQueryError = Error;

export const useGetSurveyId = <TData = Awaited<ReturnType<typeof getSurveyId>>, TError = Error>(
  id: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getSurveyId>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSurveyIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const putSurveyId = (id: string, updateSurveyDialogueDto: UpdateSurveyDialogueDto) => {
  return fetchInstance<void>({
    url: `/survey/${id}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: updateSurveyDialogueDto,
  });
};

export const getPutSurveyIdMutationOptions = <
  TError = HttpValidationProblemDetails | Error,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSurveyId>>,
    TError,
    { id: string; data: UpdateSurveyDialogueDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putSurveyId>>,
  TError,
  { id: string; data: UpdateSurveyDialogueDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putSurveyId>>,
    { id: string; data: UpdateSurveyDialogueDto }
  > = props => {
    const { id, data } = props ?? {};

    return putSurveyId(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutSurveyIdMutationResult = NonNullable<Awaited<ReturnType<typeof putSurveyId>>>;
export type PutSurveyIdMutationBody = UpdateSurveyDialogueDto;
export type PutSurveyIdMutationError = HttpValidationProblemDetails | Error;

export const usePutSurveyId = <TError = HttpValidationProblemDetails | Error, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSurveyId>>,
    TError,
    { id: string; data: UpdateSurveyDialogueDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof putSurveyId>>,
  TError,
  { id: string; data: UpdateSurveyDialogueDto },
  TContext
> => {
  const mutationOptions = getPutSurveyIdMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getTeamId = (id: string, signal?: AbortSignal) => {
  return fetchInstance<TeamDetailsDto>({ url: `/team/${id}`, method: 'GET', signal });
};

export const getGetTeamIdQueryKey = (id: string) => {
  return [`/team/${id}`] as const;
};

export const getGetTeamIdQueryOptions = <TData = Awaited<ReturnType<typeof getTeamId>>, TError = Error>(
  id: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getTeamId>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTeamIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTeamId>>> = ({ signal }) => getTeamId(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getTeamId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTeamIdQueryResult = NonNullable<Awaited<ReturnType<typeof getTeamId>>>;
export type GetTeamIdQueryError = Error;

export const useGetTeamId = <TData = Awaited<ReturnType<typeof getTeamId>>, TError = Error>(
  id: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getTeamId>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetTeamIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const deleteTeamId = (id: string) => {
  return fetchInstance<void>({ url: `/team/${id}`, method: 'DELETE' });
};

export const getDeleteTeamIdMutationOptions = <TError = Error, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteTeamId>>, TError, { id: string }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof deleteTeamId>>, TError, { id: string }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteTeamId>>, { id: string }> = props => {
    const { id } = props ?? {};

    return deleteTeamId(id);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteTeamIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteTeamId>>>;

export type DeleteTeamIdMutationError = Error;

export const useDeleteTeamId = <TError = Error, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteTeamId>>, TError, { id: string }, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof deleteTeamId>>, TError, { id: string }, TContext> => {
  const mutationOptions = getDeleteTeamIdMutationOptions(options);

  return useMutation(mutationOptions);
};

export const putTeamId = (id: string, createUpdateTeamDto: CreateUpdateTeamDto) => {
  return fetchInstance<void>({
    url: `/team/${id}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: createUpdateTeamDto,
  });
};

export const getPutTeamIdMutationOptions = <
  TError = HttpValidationProblemDetails | Error,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putTeamId>>,
    TError,
    { id: string; data: CreateUpdateTeamDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putTeamId>>,
  TError,
  { id: string; data: CreateUpdateTeamDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putTeamId>>,
    { id: string; data: CreateUpdateTeamDto }
  > = props => {
    const { id, data } = props ?? {};

    return putTeamId(id, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutTeamIdMutationResult = NonNullable<Awaited<ReturnType<typeof putTeamId>>>;
export type PutTeamIdMutationBody = CreateUpdateTeamDto;
export type PutTeamIdMutationError = HttpValidationProblemDetails | Error;

export const usePutTeamId = <TError = HttpValidationProblemDetails | Error, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putTeamId>>,
    TError,
    { id: string; data: CreateUpdateTeamDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof putTeamId>>,
  TError,
  { id: string; data: CreateUpdateTeamDto },
  TContext
> => {
  const mutationOptions = getPutTeamIdMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getTeam = (signal?: AbortSignal) => {
  return fetchInstance<TeamDtoListResultDto>({ url: `/team`, method: 'GET', signal });
};

export const getGetTeamQueryKey = () => {
  return [`/team`] as const;
};

export const getGetTeamQueryOptions = <TData = Awaited<ReturnType<typeof getTeam>>, TError = unknown>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getTeam>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTeamQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTeam>>> = ({ signal }) => getTeam(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getTeam>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTeamQueryResult = NonNullable<Awaited<ReturnType<typeof getTeam>>>;
export type GetTeamQueryError = unknown;

export const useGetTeam = <TData = Awaited<ReturnType<typeof getTeam>>, TError = unknown>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getTeam>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetTeamQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postTeam = (createUpdateTeamDto: CreateUpdateTeamDto) => {
  return fetchInstance<CreateResponseDto>({
    url: `/team/create`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createUpdateTeamDto,
  });
};

export const getPostTeamMutationOptions = <
  TError = HttpValidationProblemDetails | Error,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postTeam>>, TError, { data: CreateUpdateTeamDto }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof postTeam>>, TError, { data: CreateUpdateTeamDto }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postTeam>>, { data: CreateUpdateTeamDto }> = props => {
    const { data } = props ?? {};

    return postTeam(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostTeamMutationResult = NonNullable<Awaited<ReturnType<typeof postTeam>>>;
export type PostTeamMutationBody = CreateUpdateTeamDto;
export type PostTeamMutationError = HttpValidationProblemDetails | Error;

export const usePostTeam = <TError = HttpValidationProblemDetails | Error, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postTeam>>, TError, { data: CreateUpdateTeamDto }, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof postTeam>>, TError, { data: CreateUpdateTeamDto }, TContext> => {
  const mutationOptions = getPostTeamMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getTicket = (ticketFilterListRequestDto: TicketFilterListRequestDto) => {
  return fetchInstance<TicketListDtoListResultDto>({
    url: `/ticket`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: ticketFilterListRequestDto,
  });
};

export const getGetTicketQueryKey = (ticketFilterListRequestDto: TicketFilterListRequestDto) => {
  return [`/ticket`, ticketFilterListRequestDto] as const;
};

export const getGetTicketQueryOptions = <
  TData = Awaited<ReturnType<typeof getTicket>>,
  TError = HttpValidationProblemDetails,
>(
  ticketFilterListRequestDto: TicketFilterListRequestDto,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getTicket>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTicketQueryKey(ticketFilterListRequestDto);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTicket>>> = () => getTicket(ticketFilterListRequestDto);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getTicket>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTicketQueryResult = NonNullable<Awaited<ReturnType<typeof getTicket>>>;
export type GetTicketQueryError = HttpValidationProblemDetails;

export const useGetTicket = <TData = Awaited<ReturnType<typeof getTicket>>, TError = HttpValidationProblemDetails>(
  ticketFilterListRequestDto: TicketFilterListRequestDto,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getTicket>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetTicketQueryOptions(ticketFilterListRequestDto, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getTicketId = (id: string, signal?: AbortSignal) => {
  return fetchInstance<TicketListDto>({ url: `/ticket/${id}`, method: 'GET', signal });
};

export const getGetTicketIdQueryKey = (id: string) => {
  return [`/ticket/${id}`] as const;
};

export const getGetTicketIdQueryOptions = <TData = Awaited<ReturnType<typeof getTicketId>>, TError = Error>(
  id: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getTicketId>>, TError, TData> }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTicketIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTicketId>>> = ({ signal }) => getTicketId(id, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getTicketId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTicketIdQueryResult = NonNullable<Awaited<ReturnType<typeof getTicketId>>>;
export type GetTicketIdQueryError = Error;

export const useGetTicketId = <TData = Awaited<ReturnType<typeof getTicketId>>, TError = Error>(
  id: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getTicketId>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetTicketIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postTicket = (createTicketDto: CreateTicketDto) => {
  const formData = postTicketMutator(createTicketDto);
  return fetchInstance<CreateResponseDto>({
    url: `/ticket/create`,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
  });
};

export const getPostTicketMutationOptions = <TError = HttpValidationProblemDetails, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postTicket>>, TError, { data: CreateTicketDto }, TContext>;
}): UseMutationOptions<Awaited<ReturnType<typeof postTicket>>, TError, { data: CreateTicketDto }, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postTicket>>, { data: CreateTicketDto }> = props => {
    const { data } = props ?? {};

    return postTicket(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostTicketMutationResult = NonNullable<Awaited<ReturnType<typeof postTicket>>>;
export type PostTicketMutationBody = CreateTicketDto;
export type PostTicketMutationError = HttpValidationProblemDetails;

export const usePostTicket = <TError = HttpValidationProblemDetails, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postTicket>>, TError, { data: CreateTicketDto }, TContext>;
}): UseMutationResult<Awaited<ReturnType<typeof postTicket>>, TError, { data: CreateTicketDto }, TContext> => {
  const mutationOptions = getPostTicketMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getTicketFilters = (signal?: AbortSignal) => {
  return fetchInstance<TicketFiltersDto>({ url: `/ticket/filters`, method: 'GET', signal });
};

export const getGetTicketFiltersQueryKey = () => {
  return [`/ticket/filters`] as const;
};

export const getGetTicketFiltersQueryOptions = <
  TData = Awaited<ReturnType<typeof getTicketFilters>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getTicketFilters>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTicketFiltersQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTicketFilters>>> = ({ signal }) => getTicketFilters(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getTicketFilters>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTicketFiltersQueryResult = NonNullable<Awaited<ReturnType<typeof getTicketFilters>>>;
export type GetTicketFiltersQueryError = unknown;

export const useGetTicketFilters = <TData = Awaited<ReturnType<typeof getTicketFilters>>, TError = unknown>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getTicketFilters>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetTicketFiltersQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postTicketCallToAction = (ticketCallToActionDto: TicketCallToActionDto) => {
  return fetchInstance<void>({
    url: `/ticket/call-to-action`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: ticketCallToActionDto,
  });
};

export const getPostTicketCallToActionMutationOptions = <
  TError = HttpValidationProblemDetails,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postTicketCallToAction>>,
    TError,
    { data: TicketCallToActionDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postTicketCallToAction>>,
  TError,
  { data: TicketCallToActionDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postTicketCallToAction>>,
    { data: TicketCallToActionDto }
  > = props => {
    const { data } = props ?? {};

    return postTicketCallToAction(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostTicketCallToActionMutationResult = NonNullable<Awaited<ReturnType<typeof postTicketCallToAction>>>;
export type PostTicketCallToActionMutationBody = TicketCallToActionDto;
export type PostTicketCallToActionMutationError = HttpValidationProblemDetails;

export const usePostTicketCallToAction = <TError = HttpValidationProblemDetails, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postTicketCallToAction>>,
    TError,
    { data: TicketCallToActionDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof postTicketCallToAction>>,
  TError,
  { data: TicketCallToActionDto },
  TContext
> => {
  const mutationOptions = getPostTicketCallToActionMutationOptions(options);

  return useMutation(mutationOptions);
};

export const postTicketExport = (ticketFilterListRequestDto: TicketFilterListRequestDto) => {
  return fetchInstance<Blob>({
    url: `/ticket/export`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: ticketFilterListRequestDto,
    responseType: 'blob',
  });
};

export const getPostTicketExportMutationOptions = <
  TError = HttpValidationProblemDetails,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postTicketExport>>,
    TError,
    { data: TicketFilterListRequestDto },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postTicketExport>>,
  TError,
  { data: TicketFilterListRequestDto },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postTicketExport>>,
    { data: TicketFilterListRequestDto }
  > = props => {
    const { data } = props ?? {};

    return postTicketExport(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostTicketExportMutationResult = NonNullable<Awaited<ReturnType<typeof postTicketExport>>>;
export type PostTicketExportMutationBody = TicketFilterListRequestDto;
export type PostTicketExportMutationError = HttpValidationProblemDetails;

export const usePostTicketExport = <TError = HttpValidationProblemDetails, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postTicketExport>>,
    TError,
    { data: TicketFilterListRequestDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof postTicketExport>>,
  TError,
  { data: TicketFilterListRequestDto },
  TContext
> => {
  const mutationOptions = getPostTicketExportMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getUserOrganisationInformation = (signal?: AbortSignal) => {
  return fetchInstance<AccountAuthorisationDtoListResultDto>({
    url: `/user/organisation-information`,
    method: 'GET',
    signal,
  });
};

export const getGetUserOrganisationInformationQueryKey = () => {
  return [`/user/organisation-information`] as const;
};

export const getGetUserOrganisationInformationQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserOrganisationInformation>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserOrganisationInformation>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserOrganisationInformationQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserOrganisationInformation>>> = ({ signal }) =>
    getUserOrganisationInformation(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserOrganisationInformation>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserOrganisationInformationQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserOrganisationInformation>>
>;
export type GetUserOrganisationInformationQueryError = unknown;

export const useGetUserOrganisationInformation = <
  TData = Awaited<ReturnType<typeof getUserOrganisationInformation>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getUserOrganisationInformation>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserOrganisationInformationQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
