import warningIcon from '@iconify/icons-material-symbols/warning-outline';
import { useTranslation } from 'react-i18next';
import { useGetConnectionInformation } from '#edsn/api/pie-bff';
import { Card } from '../card/Card';
import { CollapsibleCard } from '../collapsible-card/CollapsibleCard';
import { CopyButton } from '../copy-button/CopyButton';
import { DescriptionList, DescriptionListItem } from '../description-list/DescriptionList';
import { Icon } from '../icon/Icon';
import { Stack } from '../stack/Stack';
import { Text } from '../text/Text';
import { ConditionalWrapper } from '#pie/utils/ConditionalWrapper';
import { formatNl } from '#pie/utils/date';

export interface FailureInfoProps {
  ean18: string;
  isCollapsible?: boolean;
  showEan?: boolean;
  showNoFailure?: boolean;
}

export function FailureInfo({ ean18, isCollapsible = true, showEan = true, showNoFailure = false }: FailureInfoProps) {
  const { data, isLoading } = useGetConnectionInformation({ ean18 });
  const { t } = useTranslation();

  if (isLoading || !data) return null;

  const { failureEventCode, failureEventRemark, failureEventDate, ticketNumber, website, regionalGridOperator } = data;

  const hasFailure = !!failureEventCode;
  const hasBorder = hasFailure ? 'error' : 'success';
  const variant = hasFailure ? 'malfunction' : 'no_malfunction';

  const content = hasFailure ? (
    <DescriptionList>
      <DescriptionListItem title={t('failure_info.list_item.failure_event_code')}>
        {failureEventCode}
      </DescriptionListItem>
      <DescriptionListItem title={t('failure_info.list_item.failure_event_remark')}>
        {failureEventRemark}
      </DescriptionListItem>
      <DescriptionListItem title={t('failure_info.list_item.failure_event_date')}>
        {failureEventDate ? formatNl(new Date(failureEventDate), 'P') : '-'}
      </DescriptionListItem>
      {regionalGridOperator?.phone && (
        <DescriptionListItem title={t('common.phone')}>{regionalGridOperator?.phone}</DescriptionListItem>
      )}
      <DescriptionListItem title={t('common.ticket_number')}>{ticketNumber || '-'}</DescriptionListItem>
      {website && <DescriptionListItem title={t('common.link_to_website')}>{website}</DescriptionListItem>}
    </DescriptionList>
  ) : (
    t('connection_information_status.failure_event_code')
  );

  const status = failureEventCode
    ? t('ticket_create.ean.error')
    : t('connection_information_status.failure_event_code');

  const title = showEan ? (
    <CopyButton as="span" role="button" content={ean18} aria-label={ean18}>
      {ean18}
    </CopyButton>
  ) : (
    t('failure_info.list_item.failure_connection_info')
  );

  return hasFailure || showNoFailure ? (
    <ConditionalWrapper
      condition={isCollapsible}
      wrapper={children => (
        <CollapsibleCard
          title={title}
          icon={warningIcon}
          status={status}
          variant={variant}
          hasBorder={hasBorder}
          aria-label={t('failure_info.list_item.failure_connection_info')}
        >
          {children}
        </CollapsibleCard>
      )}
      elseWrapper={children => (
        <Card
          heading={
            <div className="flex items-center gap-2">
              <Icon icon={warningIcon} className="text-primary-dark" />
              <Text as="h6" variant="h6">
                {title}
              </Text>
            </div>
          }
          size="md"
          shadow={false}
          hasBorder={hasBorder}
        >
          <Stack gap="md" divider="line">
            {children}
          </Stack>
        </Card>
      )}
    >
      {content}
    </ConditionalWrapper>
  ) : undefined;
}
