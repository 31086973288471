import { useController } from 'react-hook-form';
import { useExtendedRules } from '../formHelpers';
import type { ComponentProps } from 'react';
import type { FieldValues, UseControllerProps } from 'react-hook-form';
import { InputField } from '#pie/components/input-field/InputField';
import { InputRating } from '#pie/components/input-rating/InputRating';

type FormRatingProps<T extends FieldValues> = ComponentProps<typeof InputRating> &
  Pick<UseControllerProps<T>, 'disabled' | 'name' | 'rules' | 'defaultValue'> &
  Pick<ComponentProps<typeof InputField>, 'label'>;

export function FormRating<T extends FieldValues>({
  name,
  disabled,
  rules: originalRules,
  defaultValue,
  label,
  ...props
}: FormRatingProps<T>) {
  const rules = useExtendedRules(originalRules);
  const {
    field,
    fieldState: { error },
  } = useController({
    defaultValue,
    disabled,
    name,
    rules,
  });

  return (
    <InputField name={name} isRequired={!!rules?.required} label={label} error={error?.message}>
      <InputRating id={name} disabled={disabled} {...field} {...props} />
    </InputField>
  );
}
