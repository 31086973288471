import { useController } from 'react-hook-form';
import { useExtendedRules } from '../formHelpers';
import type { ComponentProps } from 'react';
import type { FieldValues, UseControllerProps } from 'react-hook-form';
import { InputField } from '#pie/components/input-field/InputField';
import { InputRadio } from '#pie/components/input-radio/InputRadio';

type FormRadioProps<T extends FieldValues> = ComponentProps<typeof InputRadio> &
  Pick<UseControllerProps<T>, 'disabled' | 'name' | 'rules' | 'defaultValue'> &
  Pick<ComponentProps<typeof InputField>, 'label' | 'infoTooltip'>;

export function FormRadio<T extends FieldValues>({
  name,
  disabled,
  rules: originalRules,
  defaultValue,
  label,
  infoTooltip,
  ...props
}: FormRadioProps<T>) {
  const rules = useExtendedRules(originalRules);
  const {
    fieldState: { error },
    field,
  } = useController({
    defaultValue,
    disabled,
    name,
    rules,
  });

  /* c8 ignore start */
  const handleChange = (value: string) => {
    const parsedValue = value === 'true' ? true : value === 'false' ? false : value;
    field.onChange(parsedValue);
  };

  const stringValue = typeof field.value === 'boolean' ? field.value.toString() : field.value;
  /* c8 ignore end */
  return (
    <InputField
      name={name}
      isRequired={!!rules?.required}
      label={label}
      error={error?.message}
      infoTooltip={infoTooltip}
    >
      <InputRadio
        id={name}
        disabled={disabled}
        isError={!!error}
        {...field}
        value={stringValue}
        onChange={handleChange}
        {...props}
      />
    </InputField>
  );
}
