import { useTranslation } from 'react-i18next';
import { type CreateDataRequestDto, DataRelatedToDataset } from '#edsn/api/pie-bff';
import { typedFormFields } from '#pie/utils/typedFormFields';

export const RelatedToDataNote = () => {
  const { t } = useTranslation('dataRequest');
  const { FormTextArea, ConditionalField } = typedFormFields<CreateDataRequestDto>();
  return (
    <ConditionalField
      field="dataRelatedToDatasetNote"
      condition={fieldValue => {
        return fieldValue('dataRelatedToDataset') === DataRelatedToDataset.Yes;
      }}
    >
      <FormTextArea
        label={t('data_related_to_dataset_note')}
        name="dataRelatedToDatasetNote"
        showWordCount
        required
        fullWidth
        rules={{
          maxLength: 2000,
          minLength: 2,
          required: true,
        }}
      />
    </ConditionalField>
  );
};
