import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';
import { useGetConnectionInformation } from '#edsn/api/pie-bff';
import { Card } from '../card/Card';
import { DescriptionList, DescriptionListItem } from '../description-list/DescriptionList';
import { Stack } from '../stack/Stack';
import { Text } from '../text/Text';
import type { IconProps } from '../icon/Icon';
import { CollapsibleCard } from '#pie/components/collapsible-card/CollapsibleCard';
import { productIcons } from '#pie/constants/productIcons';
import { products } from '#pie/constants/products';
import { ConditionalWrapper } from '#pie/utils/ConditionalWrapper';
import { formatNl } from '#pie/utils/date';

export interface ConnectionInfoProps {
  ean18: string;
  isCollapsible?: boolean;
  icon?: IconProps['icon'];
}

export function ConnectionInfo({ ean18, isCollapsible = true }: ConnectionInfoProps) {
  const { data, isLoading } = useGetConnectionInformation({ ean18 });
  const { t } = useTranslation();

  if (isLoading || !data) return null;

  const {
    administrativeStatus,
    deviceInUseDate,
    deviceNumber,
    deviceStatus,
    deviceType,
    failureEventCode,
    isLargeConsumerOrDeviceless,
    product,
    rawRegionalGridOperatorEan,
    regionalGridOperator,
    supplier,
    operationalStatus,
  } = data;

  const content = (
    <DescriptionList>
      <DescriptionListItem title={t('connection_information.label_title.raw_regional_grid_operator_ean')}>
        {rawRegionalGridOperatorEan} {regionalGridOperator?.name}
      </DescriptionListItem>
      <DescriptionListItem title={t('connection_information.label_title.product')}>
        {products[product]}
      </DescriptionListItem>
      <DescriptionListItem title={t('connection_information.label_title.device_status')}>
        {deviceStatus}
      </DescriptionListItem>
      <DescriptionListItem title={t('connection_information.label_title.large_consumer_or_deviceless')}>
        {isLargeConsumerOrDeviceless ? t('common.yes') : t('common.no')}
      </DescriptionListItem>
      <DescriptionListItem title={t('connection_information.label_title.device_number')}>
        {deviceNumber}
      </DescriptionListItem>
      <DescriptionListItem title={t('connection_information.label_title.device_type')}>
        {deviceInUseDate && formatNl(new Date(deviceInUseDate), 'P')}
      </DescriptionListItem>
      <DescriptionListItem title={t('connection_information.label_title.device_type')}>
        {deviceType}
      </DescriptionListItem>
      <DescriptionListItem title={t('connection_information.label_title.operational_status')}>
        {operationalStatus}
      </DescriptionListItem>
      <DescriptionListItem title={t('connection_information.label_title.administrative_status')}>
        {administrativeStatus}
      </DescriptionListItem>
      <DescriptionListItem title={t('connection_information.label_title.supplier')}>{supplier}</DescriptionListItem>
    </DescriptionList>
  );

  const status = failureEventCode ? 'Storingen' : 'Er is geen storing bekend';

  return (
    <ConditionalWrapper
      condition={isCollapsible}
      wrapper={children => (
        <CollapsibleCard
          title={
            product === 'Gas' ? t('ticket_detail.connection_info_gas') : t('ticket_detail.connection_info_electricity')
          }
          icon={productIcons[product]}
          status={status}
          variant={failureEventCode ? 'malfunction' : 'no_malfunction'}
          aria-label={`${ean18} ${status}`}
        >
          {children}
        </CollapsibleCard>
      )}
      elseWrapper={children => (
        <Card
          heading={
            <div className="flex items-center gap-2">
              <Icon icon={productIcons[product]!} className="text-primary-dark" />
              <Text as="h6" variant="h6">
                {product === 'Gas'
                  ? t('ticket_detail.connection_info_gas')
                  : t('ticket_detail.connection_info_electricity')}
              </Text>
            </div>
          }
          size="md"
          shadow={false}
        >
          <Stack gap="md" divider="line">
            {children}
          </Stack>
        </Card>
      )}
    >
      {content}
    </ConditionalWrapper>
  );
}
