/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Edsn.WebApi.PiE
 * OpenAPI spec version: 1.0
 */

export type DataLevelZipCode = (typeof DataLevelZipCode)[keyof typeof DataLevelZipCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DataLevelZipCode = {
  ZipCode6: 'ZipCode6',
  ZipCode4: 'ZipCode4',
  Other: 'Other',
} as const;
