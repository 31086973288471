/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Edsn.WebApi.PiE
 * OpenAPI spec version: 1.0
 */

export type SimpleAnswer = (typeof SimpleAnswer)[keyof typeof SimpleAnswer];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SimpleAnswer = {
  Yes: 'Yes',
  No: 'No',
} as const;
