import downloadIcon from '@iconify/icons-material-symbols/download';
import { Button } from '../button/Button';
import type { PropsWithChildren } from 'react';
import { trpc } from '#pie/trpc';

interface Props extends PropsWithChildren {
  title: string;
}

export const CraftAssetDownload = ({ title, children }: Props) => {
  const { data, isLoading } = trpc.globals.asset.useQuery({
    title,
  });

  return (
    <Button as="a" variant="linkPrimary" download href={data?.url} iconStart={downloadIcon} isLoading={isLoading}>
      {children || title}
    </Button>
  );
};
