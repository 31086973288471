import { useTranslation } from 'react-i18next';
import { MarketRole } from '#edsn/api/pie-bff/generated/model/marketRole';

export const useMarketRoleOptions = () => {
  const { t } = useTranslation();
  const marketRoles = Object.values(MarketRole)
    .filter(m => !(['UNKNOWN'] as Array<MarketRole>).includes(m))
    .map(m => ({ label: t(`common.market_roles.${m}`), value: m }));

  return marketRoles;
};
