/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Edsn.WebApi.PiE
 * OpenAPI spec version: 1.0
 */

export type DataType = (typeof DataType)[keyof typeof DataType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DataType = {
  Asset: 'Asset',
  Consumption: 'Consumption',
  KeyFigures: 'KeyFigures',
} as const;
