import AddIcon from '@iconify/icons-material-symbols/add';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getTeam, usePostTeam } from '#edsn/api/pie-bff';
import type { CreateUpdateTeamDto } from '#edsn/api/pie-bff';
import { Button } from '../button/Button';
import { Stack } from '../stack/Stack';
import { useToast } from '../toast/ToastContext';
import type { SubmitHandler } from 'react-hook-form';
import { Dialog } from '#pie/components/layout/dialog/Dialog';
import { typedFormFields } from '#pie/utils/typedFormFields';

export const CreateTeamForm = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = useState(false);
  const { addToast } = useToast();
  const formMethods = useForm<CreateUpdateTeamDto>();

  const { handleSubmit, reset } = formMethods;

  const { mutate, isLoading } = usePostTeam({
    mutation: {
      onError: () => {
        addToast({
          message: t('organisation_management.tab.team_management.create_team.toast.error.message'),
          title: t('organisation_management.tab.team_management.create_team.toast.error.title'),
          type: 'error',
        });
      },
      onSuccess: (data, variables) => {
        if (data.id) {
          gtag('event', 'create_team', {});
          queryClient.invalidateQueries([getTeam.name]);
          queryClient.invalidateQueries(['/team']);
          addToast({
            message: t('organisation_management.tab.team_management.create_team.toast.success.message', {
              team: variables.data.name,
            }),
            title: t('organisation_management.tab.team_management.create_team.toast.success.title'),
            type: 'success',
          });
          setIsOpen(false);
          reset();
        }
      },
    },
  });

  const onSubmit: SubmitHandler<CreateUpdateTeamDto> = data => {
    mutate({ data });
  };

  const { FormText } = typedFormFields<CreateUpdateTeamDto>();

  return (
    <>
      <Button iconStart={AddIcon} variant="secondary" onClick={() => setIsOpen(true)}>
        {t('organisation_management.tab.team_management.button.new')}
      </Button>

      {isOpen && (
        <Dialog
          title={t('organisation_management.tab.team_management.button.create')}
          size="md"
          open={isOpen}
          onOpenChange={setIsOpen}
        >
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack gap="lg">
                <Stack gap="sm">
                  <FormText autoFocus fullWidth name="name" label={t('common.name')} rules={{ required: true }} />
                </Stack>
                <Button isLoading={isLoading} type="submit" variant="secondary">
                  {t('organisation_management.tab.team_management.button.create')}
                </Button>
              </Stack>
            </form>
          </FormProvider>
        </Dialog>
      )}
    </>
  );
};
