import { useAtom } from 'jotai';
import { type ComponentProps, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetKnowledgeArticleCategories } from '#edsn/api/pie-bff';
import { Button } from '../button/Button';
import { InputRadio } from '../input-radio/InputRadio';
import { Drawer } from '../layout/drawer/Drawer';
import { Skeleton } from '../skeleton/Skeleton';
import { Stack } from '../stack/Stack';
import { Text } from '../text/Text';
import type { DrawerProps } from '../layout/drawer/Drawer';
import { knowledgeArticleFiltersAtom } from '#pie/stores/knowledgeArticle';

export const KnowledgeArticleFilterPanel = (props: Omit<DrawerProps, 'title'>) => {
  const { t } = useTranslation();
  const [activeFilters, setActiveFilters] = useAtom(knowledgeArticleFiltersAtom);

  const { data: categories, isLoading } = useGetKnowledgeArticleCategories();

  const selectedMainCategory = useMemo(
    () => categories?.items.find(c => c.id === activeFilters.mainCategoryIds?.[0]),
    [categories, activeFilters.mainCategoryIds]
  );

  const categoryOptions = useMemo<ComponentProps<typeof InputRadio>['options']>(
    () => [
      { label: t('common.all_categories'), value: '' },
      ...(categories?.items.map(option => ({ label: option.name, value: option.id })) || []),
    ],
    [categories]
  );
  const subCategoryOptions = useMemo(
    () => [
      { label: t('common.all_categories'), value: '' },
      ...(selectedMainCategory
        ? selectedMainCategory.subCategories.map(option => ({ label: option.name, value: option.id }))
        : []),
    ],
    [categories, selectedMainCategory]
  );

  return (
    <Drawer title={t('knowledge_base.article_table.filter_panel.title')} {...props}>
      <Stack gap="lg" divider="line">
        <Stack>
          <Text variant="h5" className="mb-4">
            {t('common.main_category')}
          </Text>
          {isLoading ? (
            Array(3).map((_, i) => <Skeleton key={i} />)
          ) : (
            <InputRadio
              name="mainCategory"
              options={categoryOptions}
              value={activeFilters.mainCategoryIds?.[0] || ''}
              onChange={value => {
                setActiveFilters({
                  ...activeFilters,
                  mainCategoryIds: [value],
                  subCategoryIds: [''],
                });
              }}
            />
          )}
        </Stack>
        <Stack>
          <Text variant="h5" className="mb-4">
            {t('common.sub_category')}
          </Text>
          {isLoading ? (
            Array(3).map((_, i) => <Skeleton key={i} />)
          ) : (
            <InputRadio
              name="subCategory"
              options={subCategoryOptions}
              value={activeFilters.subCategoryIds?.[0] || ''}
              onValueChange={value => {
                setActiveFilters({
                  ...activeFilters,
                  subCategoryIds: [value],
                });
              }}
            />
          )}
        </Stack>
        <Stack direction="row" gap="md">
          <Button variant="secondary" className="grow" onClick={() => props.onOpenChange?.(false)}>
            {t('common.button.show')}
          </Button>
          <Button
            variant="ghost"
            onClick={() => {
              setActiveFilters({});
              props.onOpenChange?.(false);
            }}
          >
            {t('knowledge_base.article_table.filter_panel.clear')}
          </Button>
        </Stack>
      </Stack>
    </Drawer>
  );
};
