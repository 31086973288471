import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { makeZodI18nMap } from 'zod-i18n-map';
import type common from '#pie/i18n/locales/nl-NL/common.json';
import type { PropsWithChildren } from 'react';
import { buildErrorMap, requiredError } from '#pie/components/form/errorMaps';

export function Translations({ children }: PropsWithChildren) {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'zod' as keyof typeof common,
  });
  const i18nErrorMap = makeZodI18nMap({ handlePath: false, ns: 'common', t });
  const errorMap = buildErrorMap({ errors: [requiredError], fallbackErrorMap: i18nErrorMap });

  // Set error map globally and not on zodResolver so that local errorMaps can overwrite with custom error message
  z.setErrorMap(errorMap);

  return children;
}
