import { useTranslation } from 'react-i18next';
import { type CreateDataRequestDto, SimpleAnswer } from '#edsn/api/pie-bff';
import { typedFormFields } from '#pie/utils/typedFormFields';

const { ConditionalField, FormText } = typedFormFields<CreateDataRequestDto>();

export const EarlierSameDataNote = () => {
  const { t } = useTranslation('dataRequest');

  return (
    <ConditionalField
      field="earlierSameDataNote"
      condition={formValue => formValue('earlierSameData') === SimpleAnswer.Yes}
    >
      <FormText label={t('earlier_same_data_note')} name="earlierSameDataNote" fullWidth />
    </ConditionalField>
  );
};
