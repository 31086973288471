import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import type { CreateUpdateContactDetailsDto } from '#edsn/api/pie-bff';
import { getContactDetails, getGetContactDetailsIdQueryKey, usePostContactDetailsCreate } from '#edsn/api/pie-bff';
import { FormOrganisationUser } from './FormOrganisationUser';
import type { SubmitHandler } from 'react-hook-form';
import { Button } from '#pie/components/button/Button';
import { Dialog } from '#pie/components/layout/dialog/Dialog';
import { Stack } from '#pie/components/stack/Stack';
import { Text } from '#pie/components/text/Text';
import { ToastButton } from '#pie/components/toast/Toast';
import { useToast } from '#pie/components/toast/ToastContext';

export const CreateOrganisationUser = () => {
  const { addToast } = useToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const [openActivateDialog, setOpenActivateDialog] = useState(false);
  const [email, setEmail] = useState<string>();

  const { mutate, isLoading } = usePostContactDetailsCreate({
    mutation: {
      onError: async error => {
        switch (!(error instanceof Error) && error.code) {
          case 'Contact.ActiveExists': {
            addToast({
              message: t('organisation_management.user_management.update.toast.error.active_exists.message'),
              title: t('organisation_management.user_management.update.toast.error.active_exists.title'),
              type: 'error',
            });
            break;
          }
          case 'Contact.InactiveExists': {
            setOpenActivateDialog(true);
            break;
          }
          default: {
            addToast({
              message: t('organisation_management.user_management.create.toast.error.message'),
              title: t('organisation_management.user_management.create.toast.error.title'),
              type: 'error',
            });
          }
        }
      },
      onSuccess: ({ id }) => {
        addToast({
          action: (
            <ToastButton as={Link} to={`/organisatiebeheer/gebruikersbeheer/${id}`}>
              {t('organisation_management.user_management.create.toast.success.button')}
            </ToastButton>
          ),
          message: t('organisation_management.user_management.create.toast.success.message'),
          title: t('organisation_management.user_management.create.toast.success.title'),
          type: 'success',
        });
        queryClient.removeQueries([getContactDetails.name]);
        queryClient.removeQueries(getGetContactDetailsIdQueryKey(id));
        navigate('/organisatiebeheer/gebruikersbeheer/');
      },
    },
  });

  const onSubmit: SubmitHandler<CreateUpdateContactDetailsDto> = data => {
    setEmail(data.email);
    mutate({ data: { ...data, activateIfInactive: openActivateDialog } });
  };

  return (
    <FormOrganisationUser
      onSubmit={onSubmit}
      isLoading={isLoading}
      confirmationDialog={
        <Dialog
          title={t('organisation_management.user_management.create.dialog.activate.title')}
          open={openActivateDialog}
          onOpenChange={setOpenActivateDialog}
          portal={false}
        >
          <Stack gap="md">
            <Text>
              {t('organisation_management.user_management.create.dialog.activate.message', {
                email,
              })}
            </Text>
            <div className="grid-auto grid auto-cols-fr grid-flow-col gap-2">
              <Button variant="ghost" onClick={() => setOpenActivateDialog(false)}>
                {t('common.button.cancel')}
              </Button>
              <Button variant="danger" type="submit" isLoading={isLoading}>
                {t('organisation_management.user_management.create.dialog.activate.submit')}
              </Button>
            </div>
          </Stack>
        </Dialog>
      }
    />
  );
};
