/* eslint-disable no-restricted-imports */
import type { FieldValues } from 'react-hook-form';
import { ConditionalField } from '#pie/components/form/conditional-field/ConditionalField';
import { FormCheckbox } from '#pie/components/form/form-checkbox/FormCheckbox';
import { FormCombobox } from '#pie/components/form/form-combobox/FormCombobox';
import { FormDate } from '#pie/components/form/form-date/FormDate';
import { FormDropdown } from '#pie/components/form/form-dropdown/FormDropdown';
import { FormField } from '#pie/components/form/form-field/FormField';
import { FormFile } from '#pie/components/form/form-file/FormFile';
import { FormRadio } from '#pie/components/form/form-radio/FormRadio';
import { FormRating } from '#pie/components/form/form-rating/FormRating';
import { FormText } from '#pie/components/form/form-text/FormText';
import { FormTextArea } from '#pie/components/form/form-text-area/FormTextArea';
import { FormToggle } from '#pie/components/form/form-toggle/FormToggle';

export const typedFormFields = <T extends FieldValues>() => ({
  ConditionalField: ConditionalField<T>,
  FormCheckbox: FormCheckbox<T>,
  FormCombobox: FormCombobox<T>,
  FormDate: FormDate<T>,
  FormDropdown: FormDropdown<T>,
  FormField: FormField<T>,
  FormFile: FormFile<T>,
  FormRadio: FormRadio<T>,
  FormRating: FormRating<T>,
  FormText: FormText<T>,
  FormTextArea: FormTextArea<T>,
  FormToggle: FormToggle<T>,
});
