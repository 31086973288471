/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Edsn.WebApi.PiE
 * OpenAPI spec version: 1.0
 */

export type ConsumptionType = (typeof ConsumptionType)[keyof typeof ConsumptionType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ConsumptionType = {
  Generate: 'Generate',
  Consume: 'Consume',
  Other: 'Other',
} as const;
