import { useTranslation } from 'react-i18next';
import { useBlocker } from 'react-router-dom';
import { Button } from '../button/Button';
import { Dialog } from '../layout/dialog/Dialog';
import { Stack } from '../stack/Stack';
import { Text } from '../text/Text';

export const CancelDataServicesRequest = ({ shouldBlock }: { shouldBlock: boolean }) => {
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => shouldBlock && currentLocation.pathname !== nextLocation.pathname
  );

  const { t } = useTranslation('dataRequest');

  return (
    <Dialog open={blocker.state === 'blocked'} title={t('cancel.title')} onOpenChange={() => blocker.reset?.()}>
      <Text className="mb-6">{t('cancel.description')}</Text>
      <Stack direction="row" gap="md">
        <Button className="flex-1" onClick={() => blocker.reset?.()} type="submit" variant="ghost">
          {t('cancel.button.cancel')}
        </Button>
        <Button className="flex-1" onClick={() => blocker.proceed?.()} variant="danger">
          {t('cancel.button.submit')}
        </Button>
      </Stack>
    </Dialog>
  );
};
