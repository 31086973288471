import { useTranslation } from 'react-i18next';
import { Answer, type CreateDataRequestDto, SimpleAnswer } from '#edsn/api/pie-bff';
import { typedFormFields } from '#pie/utils/typedFormFields';

const { FormRadio, ConditionalField } = typedFormFields<CreateDataRequestDto>();

export const LegalObligation = () => {
  const { t } = useTranslation('dataRequest');
  const YesNoDontKnowOptions = [
    {
      label: t(`answer.${Answer.Yes}`),
      value: Answer.Yes,
    },
    {
      label: t(`answer.${Answer.No}`),
      value: Answer.No,
    },
    {
      label: t(`answer.${Answer.DontKnow}`),
      value: Answer.DontKnow,
    },
  ];

  return (
    <ConditionalField
      field="legalObligation"
      condition={formValue => {
        return (
          (formValue('canObtainOtherwiseNote') && formValue('canObtainOtherwiseNote')!.length > 1) ||
          formValue('canObtainOtherwise') === SimpleAnswer.No
        );
      }}
    >
      <FormRadio
        name="legalObligation"
        label={t('legal_obligation')}
        options={YesNoDontKnowOptions}
        rules={{ required: true }}
      />
    </ConditionalField>
  );
};
