import { useTranslation } from 'react-i18next';
import { FailureInfo } from '../failure-info/FailureInfo';
import { Stack } from '../stack/Stack';
import { Text } from '../text/Text';

interface Props {
  electricityEan?: string | null;
  gasEan?: string | null;
}

export const EanFailure = ({ electricityEan, gasEan }: Props) => {
  const { t } = useTranslation();
  return (
    (electricityEan || gasEan) && (
      <Stack as="section" gap="md">
        <Text as="h2" variant="h5">
          {t('ticket_create.prior_tickets.failures.title')}
        </Text>
        {electricityEan && <FailureInfo ean18={electricityEan} />}
        {gasEan && <FailureInfo ean18={gasEan} />}
      </Stack>
    )
  );
};
