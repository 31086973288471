import { useTranslation } from 'react-i18next';
import type { CreateDataRequestDto } from '#edsn/api/pie-bff';
import { DataLevel } from '#edsn/api/pie-bff';
import { CraftAssetDownload } from '#pie/components/craft-asset-download/CraftAssetDownload';
import { typedFormFields } from '#pie/utils/typedFormFields';

const { ConditionalField, FormFile } = typedFormFields<CreateDataRequestDto>();

export const PartitionLevelAddress = () => {
  const { t } = useTranslation('dataRequest');

  return (
    <ConditionalField field="partitionFile" condition={formValue => formValue('partitionLevel') === DataLevel.Address}>
      <FormFile name="partitionFile" label={t('partition_level_address')} rules={{ required: true }} />
      <CraftAssetDownload title="Aanleveren clusters voorbeeldbestand" />
    </ConditionalField>
  );
};
