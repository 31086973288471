/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Edsn.WebApi.PiE
 * OpenAPI spec version: 1.0
 */

export type LegalBasisPersonalDataReason =
  (typeof LegalBasisPersonalDataReason)[keyof typeof LegalBasisPersonalDataReason];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LegalBasisPersonalDataReason = {
  SignedCustomerMandate: 'SignedCustomerMandate',
  LegalObligation: 'LegalObligation',
  PublicTask: 'PublicTask',
  LegitimateInterest: 'LegitimateInterest',
  Other: 'Other',
} as const;
