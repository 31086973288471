import { AssetTypes } from '../fields/AssetTypes';
import { ConsumptionTypes } from '../fields/ConsumptionTypes';
import { CustomerMandateFile } from '../fields/CustomerMandateFile';
import { DataRequestTo } from '../fields/DataRequestTo';
import { DataTypeNote } from '../fields/DataTypeNote';
import { DataTypes } from '../fields/DataTypes';
import { EarlierSameData } from '../fields/EarlierSameData';
import { EarlierSameDataNote } from '../fields/EarlierSameDataNote';
import { EnergyTypes } from '../fields/EnergyTypes';
import { EnergyTypesConsumption } from '../fields/EnergyTypesConsumption';
import { FileFormats } from '../fields/FileFormats';
import { Level } from '../fields/Level';
import { LevelAddress } from '../fields/LevelAddress';
import { LevelCity } from '../fields/LevelCity';
import { LevelMunicipality } from '../fields/LevelMunicipality';
import { LevelProvince } from '../fields/LevelProvince';
import { LevelResRegio } from '../fields/LevelResRegio';
import { LevelZipCode } from '../fields/LevelZipCode';
import { PartitionLevel } from '../fields/PartitionLevel';
import { PartitionLevelAddress } from '../fields/PartitionLevelAddress';
import { PartitionLevelZipCode } from '../fields/PartitionLevelZipCode';
import { Periods } from '../fields/Periods';
import { Reason } from '../fields/Reason';
import { RelatedToData } from '../fields/RelatedToData';
import { RelatedToDataNote } from '../fields/RelatedToDataNote';
import { Stack } from '#pie/components/stack/Stack';

export const DataServicesFormStep1 = () => {
  return (
    <Stack gap="md">
      <RelatedToData />
      <RelatedToDataNote />
      <Reason />
      <DataRequestTo />
      <DataTypes />
      <EnergyTypes />
      <AssetTypes />
      <EnergyTypesConsumption />
      <ConsumptionTypes />
      <DataTypeNote />
      <Level />
      <LevelAddress />
      <CustomerMandateFile />
      <LevelZipCode />
      <LevelCity />
      <LevelProvince />
      <LevelMunicipality />
      <LevelResRegio />
      <PartitionLevel />
      <PartitionLevelAddress />
      <PartitionLevelZipCode />
      <FileFormats />
      <Periods />
      <EarlierSameData />
      <EarlierSameDataNote />
    </Stack>
  );
};
