import { CanObtainOtherwise } from './CanObtainOtherwise';
import { CanObtainOtherwiseNote } from './CanObtainOtherwiseNote';
import { LegalObligation } from './LegalObligation';
import { LegalObligationReasons } from './LegalObligationReasons';
import { PersonalDataLegalBasis } from './PersonalDataLegalBasis';
import { PersonalDataLegalBasisReason } from './PersonalDataLegalBasisReason';
import { PersonalDataLegalBasisReasonNote } from './PersonalDataLegalBasisReasonNote';
import { PersonalInformation } from './PersonalInformation';
import { Stack } from '#pie/components/stack/Stack';

export const DataServicesFormStep2 = () => {
  return (
    <Stack gap="lg">
      <CanObtainOtherwise />
      <CanObtainOtherwiseNote />
      <LegalObligation />
      <LegalObligationReasons />
      <PersonalInformation />
      <PersonalDataLegalBasis />
      <PersonalDataLegalBasisReason />
      <PersonalDataLegalBasisReasonNote />
    </Stack>
  );
};
