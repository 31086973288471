import expandIcon from '@iconify/icons-material-symbols/expand-more';
import * as Collapsible from '@radix-ui/react-collapsible';
import { useState } from 'react';
import { Icon } from '../icon/Icon';
import { Text } from '../text/Text';
import type { VariantProps } from '#pie/utils/TailwindUtils';
import type { IconProps } from '../icon/Icon';
import type { PropsWithChildren, ReactNode } from 'react';
import { tv } from '#pie/utils/TailwindUtils';

export interface CollapsibleCardProps
  extends PropsWithChildren,
    Omit<Collapsible.CollapsibleProps, 'onOpenChange' | 'title'>,
    VariantProps<typeof collapsibleCard> {
  className?: string;
  title: ReactNode;
  status?: string;
  icon?: IconProps['icon'];
}

// TODO: component name is too generic
export function CollapsibleCard({
  className,
  title,
  status,
  children,
  open: state,
  icon,
  hasBorder,
  variant,
  'aria-label': label,
  ...props
}: CollapsibleCardProps) {
  const [open, setOpen] = useState<boolean>(!!state);
  const s = collapsibleCard({ hasBorder, variant });

  return (
    <Collapsible.Root className={s.base({ className })} {...props} open={open} onOpenChange={setOpen}>
      <Collapsible.Trigger className="flex w-full items-center gap-4 bg-white p-4" aria-label={label}>
        <div className="text-primary-dark flex gap-1">
          {icon && <Icon icon={icon} />}
          <Text className="font-bold">{title}</Text>
        </div>

        <div className="ml-auto flex gap-4">
          <Icon icon={expandIcon} className="ml-auto" rotate={open ? 2 : 0} />
        </div>
      </Collapsible.Trigger>

      <Collapsible.Content className="border-t border-t-neutral-200 bg-white p-5">{children}</Collapsible.Content>
    </Collapsible.Root>
  );
}

const collapsibleCard = tv({
  base: 'border rounded border-neutral-300 overflow-hidden',
  slots: {
    status: 'text-primary',
  },
  variants: {
    hasBorder: {
      error: 'border-l-error border-l-4',
      success: 'border-l-success border-l-4',
    },
    variant: {
      malfunction: {
        status: 'text-error',
      },
      no_malfunction: {
        status: 'text-success',
      },
    },
  },
});
