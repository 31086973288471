/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Edsn.WebApi.PiE
 * OpenAPI spec version: 1.0
 */

export type ReasonForDataRequest = (typeof ReasonForDataRequest)[keyof typeof ReasonForDataRequest];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReasonForDataRequest = {
  EnergySaving: 'EnergySaving',
  Monitoring: 'Monitoring',
  Planning: 'Planning',
  Research: 'Research',
  LegalObligation: 'LegalObligation',
  Other: 'Other',
  EnergyNeighborhoodScan: 'EnergyNeighborhoodScan',
} as const;
