import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { CreateDataRequestDto } from '#edsn/api/pie-bff';
import { AddressLevel, FileFormat, Period } from '#edsn/api/pie-bff';
import type { FormCheckboxType } from '#pie/components/form/types';
import { typedFormFields } from '#pie/utils/typedFormFields';

const { ConditionalField, FormCheckbox, FormText } = typedFormFields<CreateDataRequestDto>();

export const Periods = () => {
  const { t } = useTranslation('dataRequest');

  const options: FormCheckboxType[] = [
    {
      label: t(`periods.${Period.Past5Years}`),
      value: Period.Past5Years,
    },
    {
      label: t(`periods.${Period._2023}`),
      value: Period._2023,
    },
    {
      label: t(`periods.${Period._2022}`),
      value: Period._2022,
    },
    {
      label: t(`periods.${Period._2021}`),
      value: Period._2021,
    },
    {
      label: t(`periods.${Period._2020}`),
      value: Period._2020,
    },
    {
      label: t(`periods.${Period._2019}`),
      value: Period._2019,
    },
    {
      label: t(`periods.${Period.Other}`),
      renderInput: (
        <ConditionalField
          field="periodNote"
          condition={fieldValue => !!fieldValue('periods') && fieldValue('periods')!.includes(Period.Other)}
        >
          <FormText name="periodNote" fullWidth rules={{ maxLength: 25, minLength: 2, required: true }} />
        </ConditionalField>
      ),
      value: Period.Other,
    },
  ];

  const { watch, setValue } = useFormContext<CreateDataRequestDto>();
  const gridOperatorData = watch('periods');

  useEffect(() => {
    if (gridOperatorData?.slice(-1)[0] === Period.Past5Years) {
      setValue('periods', [Period.Past5Years]);
    } else if (gridOperatorData?.includes(Period.Past5Years)) {
      setValue('periods', gridOperatorData?.filter(value => value !== Period.Past5Years));
    }
  }, [gridOperatorData, setValue]);

  return (
    <ConditionalField
      field="periods"
      condition={formValue => {
        const fileFormats = formValue('fileFormats');
        if (fileFormats && fileFormats.length > 0 && !fileFormats.includes(FileFormat.Other)) return true;
        if (formValue('fileFormatNote')) return true;
        if (formValue('addressLevel') === AddressLevel.Cluster) return true;
        if (formValue('customerMandateFile')) return true;
        return false;
      }}
    >
      <FormCheckbox
        infoTooltip={t('periods.tooltip')}
        label={t('periods')}
        name="periods"
        options={options}
        rules={{ required: true }}
      />
    </ConditionalField>
  );
};
