import { useTranslation } from 'react-i18next';
import type { CreateDataRequestDto } from '#edsn/api/pie-bff';
import { Period, SimpleAnswer } from '#edsn/api/pie-bff';
import { typedFormFields } from '#pie/utils/typedFormFields';

const { ConditionalField, FormRadio } = typedFormFields<CreateDataRequestDto>();

export const EarlierSameData = () => {
  const { t } = useTranslation('dataRequest');

  const options = [
    {
      label: t(`simple_answer.${SimpleAnswer.Yes}`),
      value: SimpleAnswer.Yes,
    },
    {
      label: t(`simple_answer.${SimpleAnswer.No}`),
      value: SimpleAnswer.No,
    },
  ];

  return (
    <ConditionalField
      field="earlierSameData"
      condition={formValue => {
        const periods = formValue('periods');
        if (periods && periods.length && !periods.includes(Period.Other)) return true;
        if (formValue('periodNote')) return true;
        return false;
      }}
    >
      <FormRadio label={t('earlier_same_data')} name="earlierSameData" options={options} rules={{ required: true }} />
    </ConditionalField>
  );
};
